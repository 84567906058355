import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Group} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function CustomerContactList(props) {
    const intl = useIntl();
    const columns = [
        {
            title: intl.formatMessage({
                id: 'customer_contacts.list.column.customer.name',
                defaultMessage: 'Customer Id'
            }),
            field: 'customer.name',
            type: 'string',
            filtering: false,
            width: 'auto'
        },
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.title', defaultMessage: 'Title'}),
            field: 'title'
        },
        {title: intl.formatMessage({id: 'customer_contacts.list.column.name', defaultMessage: 'Name'}), field: 'name'},
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.phone', defaultMessage: 'Phone'}),
            field: 'phone'
        },
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.mobile', defaultMessage: 'Mobile'}),
            field: 'mobile'
        },
        {
            title: intl.formatMessage({id: 'customer_contacts.list.column.email', defaultMessage: 'Email'}),
            field: 'email',
            type: 'string'
        }
    ];

    if (props.customerId) {
        columns.shift();
    }

    return (
        <Paper>
            <EnhancedTable
                softDeletes={true}
                title={intl.formatMessage({
                    id: 'customer_contacts.list.table.heading',
                    defaultMessage: "Consultants"
                })}
                columns={columns}
                query="customerContacts"
                mutations="customerContact"
                fields='id customer{name} title name phone mobile email'
                filter={props.customerId ? 'customer_id:' + props.customerId : ''}
                icon={<Group/>}
                baseUrl={props.customerId ? props.history.location.pathname + '/consultant' : null}
                {...props}
            />
        </Paper>
    );
}