import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { AppBar, Box, Card, CardContent, CardHeader, Tab, Tabs } from '@material-ui/core'
import { People } from '@material-ui/icons'
import PropTypes from 'prop-types'
import SubjectContactList from 'views/Admin/Subjects/SubjectContactList'
import SubjectFieldForm from 'components/Form/SubjectFieldForm'
import EditForm from 'components/Form/EditForm'
import { graphQLApi, graphQLReduceFields } from 'services/GraphQLApi'
import { useAuthDispatch } from 'contexts/Auth'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={1}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function AssignmentSubjectEdit(props) {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleTabChange = (_event, newTab) => {
    setSelectedTab(newTab);
  };

  let id = Number(props.match.params.id);
  let assignmentId = props.match.params && props.match.params.assignmentID;

  const fields = [
    {
      column: 1,
      field: "subject_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.subject_id", defaultMessage: "Subject"}),
      query: "subjects",
      filter: "",
      titleField: "name"
    },
    {
      column: 1,
      field: "contact_title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.contact_title", defaultMessage: "Contact title"}),
      input: "text"
    },
    {
      column: 1,
      field: "contact_name",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.contact_name", defaultMessage: "Contact name"}),
      input: "text"
    },
    {
      column: 1,
      field: "contact_email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.contact_email", defaultMessage: "Contact email"}),
      input: "email"
    },
    {
      column: 1,
      field: "contact_phone",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.contact_phone", defaultMessage: "Contact phone"}),
      input: "text"
    },
    {
      column: 1,
      field: "contact_department_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({
        id: "assignment_subjects.edit.label.contact_department_id",
        defaultMessage: "Contact Department"
      }),
      query: "departments",
      filter: "",
      titleField: "name"
    },
    {
      column: 1,
      field: "subject_contact_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({
        id: "assignment_subjects.edit.label.subject_contact_id",
        defaultMessage: "Subject Contact"
      }),
      query: "subjectContacts",
      filter: "",
      titleField: "name"
    },
    {
      column: 2,
      field: "customer_contact_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({
        id: "assignment_subjects.edit.label.customer_contact_id",
        defaultMessage: "Customer Contact"
      }),
      query: "customerContacts",
      filter: "",
      titleField: "name"
    },
    {
      column: 2,
      field: "outcome_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.outcome_id", defaultMessage: "Outcome"}),
      query: "outcomes",
      filter: "",
      titleField: "name"
    },
    {
      column: 2,
      field: "phoner_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.phoner_id", defaultMessage: "Phoner"}),
      query: "users",
      filter: 'role:"employee"',
      titleField: "name"
    },
    {
      column: 2,
      field: "next_contact_at",
      initial: null,
      type: "String",
      label: intl.formatMessage({
        id: "assignment_subjects.edit.label.next_contact_at",
        defaultMessage: "Next Contact At"
      }),
      input: "datetime"
    },
    {
      column: 2,
      field: "meeting_established_at",
      initial: null,
      type: "String",
      label: intl.formatMessage({
        id: "assignment_subjects.edit.label.meeting_established_at",
        defaultMessage: "Meeting Established At"
      }),
      input: "datetime",
      disabled: () => true,
    },
    {
      column: 2,
      field: "status_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.status_id", defaultMessage: "Status"}),
      query: "assignmentSubjectStatuses",
      filter: "",
      titleField: "name"
    },
    {
      column: 2,
      field: "is_confirmed",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.is_confirmed", defaultMessage: "Is Confirmed"}),
      input: "switch"
    },
    {
      column: 3,
      field: "calls_made",
      initial: 0,
      type: "Int",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.calls_made", defaultMessage: "Calls Made"}),
      input: "number"
    },
    {
      column: 3,
      field: "invoiced",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.invoiced", defaultMessage: "Invoiced"}),
      input: "text"
    },
    {
      column: 3,
      field: "credited",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.credited", defaultMessage: "Credited"}),
      input: "text"
    },
    {
      column: 3,
      field: "last_edited_by_manager_at",
      initial: null,
      type: "String",
      label: intl.formatMessage({
        id: "assignment_subjects.edit.label.last_edited_by_manager_at",
        defaultMessage: "Last Edited By Manager At"
      }),
      input: "datetime",
      disabled: () => true,
    },
    {
      column: 3,
      field: "last_edited_by_phoner_at",
      initial: null,
      type: "String",
      label: intl.formatMessage({
        id: "assignment_subjects.edit.label.last_edited_by_phoner_at",
        defaultMessage: "Last Edited By Phoner At"
      }),
      input: "datetime",
      disabled: () => true,
    },
    {
      column: 3,
      field: "is_read_only",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.is_read_only", defaultMessage: "Is Read Only"}),
      input: "switch"
    },
    {
      field: "comment",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_subjects.edit.label.comment", defaultMessage: "Comment"}),
      input: "text",
      lines: 10,
      fullWidth: true
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(graphQLReduceFields(fields, 'initial'));
  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});

  useEffect(() => {
    setIsLoading(true);
    client.query('{assignmentSubjects(filter:{id:'+id+'}) {data{id '+graphQLReduceFields(fields, 'fields')+'}}}').then(response => {
      setIsLoading(false);
      if (response) {
        const d = response.assignmentSubjects.data[0];
        setData({
          ...d,
          status_id: d.status,
          subject_id: d.subject,
          contact_department_id: d.contact_department,
          subject_contact_id: d.subject_contact,
          customer_contact_id: d.customer_contact,
          outcome_id: d.outcome,
          phoner_id: d.phoner,
        });
      }
    });
  }, []);

  const handleSave = (d) => {
    const vars = graphQLReduceFields(fields, 'variables');
    if (id) {
      vars.id = 'ID!';
      d.id = id;
    }
    client
      .mutation('assignmentSubject', vars, d, 'id ' + graphQLReduceFields(fields, 'fields'))
      .then(r => {
        if (r && r.response) {
          props.history.goBack();
        }
      });
  }

  return (
    <>
      <AppBar position="static">
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label={intl.formatMessage({id: "subjects.edit.tab.general", defaultMessage: "General"})} {...a11yProps(0)} />
          <Tab label={intl.formatMessage({id: "subjects.edit.tab.fields", defaultMessage: "Fields"})} {...a11yProps(1)} />
          <Tab label={intl.formatMessage({id: "subjects.edit.tab.contacts", defaultMessage: "Contacts"})} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <Card>
          <CardHeader
            avatar={<People color="primary"/>}
            title={intl.formatMessage({
              id: "assignment_subjects.edit.heading",
              defaultMessage: "Assignment Subjects edit"
            })}
            titleTypographyProps={{color: "primary"}}
          />
          <CardContent>
            <EditForm
              {...props}
              data={data}
              setData={setData}
              fields={fields}
              validation={validation}
              cols={3}
              isLoading={isLoading}
              save={handleSave}
            />
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <SubjectFieldForm subjectId={data.subject_id ? data.subject_id.id : null} assignmentId={assignmentId} history={props.history}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <SubjectContactList
          {...props}
          subjectId={data.subject_id ? data.subject_id.id : null}
        />
      </TabPanel>
    </>
  );
}
