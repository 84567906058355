import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress, Dialog, DialogContent,
  Grid,
  Snackbar,
  Tab,
  Tabs,
  TextField
} from "@material-ui/core";
import PropTypes from "prop-types";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={1}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function SubjectFieldForm({subjectId, assignmentId = null, history}) {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleTabChange = (_event, newTab) => {
    setSelectedTab(newTab);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [tabs, setTabs] = React.useState([]);
  const client = new graphQLApi(useAuthDispatch(), history);
  useEffect(() => {
    setIsLoading(true);
    client.query('{subjectFieldData(filter:{subject_id:' + subjectId + ' assignment_id:' + (assignmentId ? assignmentId : 'null') + '}) {' +
      'data{ id data subject_field{name category}}' +
      '}}').then(response => {
      if (response && response.hasOwnProperty('subjectFieldData')) {
        const tabCategories = {
          generel: 0,
        };
        let tabCategoryCount = 1;
        const newTabs = [
          {
            label: intl.formatMessage({id: "subject_fields.tabs.no_category", defaultMessage: "No category"}),
            fields: [],
          }
        ];
        response.subjectFieldData.data.forEach(d => {
          let tabIndex = 0;
          if (d.subject_field.category) {
            if (!tabCategories[d.subject_field.category]) {
              tabCategories[d.subject_field.category] = tabCategoryCount;
              tabIndex = tabCategoryCount;
              newTabs[tabIndex] = {
                label: d.subject_field.category,
                fields: [],
              }
              tabCategoryCount++;
            }
          }
          newTabs[tabIndex].fields.push({
            id: d.id,
            label: d.subject_field.name,
            value: d.data,
          });
        });
        setTabs(newTabs);
      }
      setIsLoading(false);
    })
  }, []);

  const [showAddField, setShowAddField] = useState(false);

  return (<>
    <Snackbar open={isLoading} anchorOrigin={{vertical: "top", horizontal: "center"}} message={"Loading..."}
              action={<CircularProgress color="inherit"/>}/>
    <Dialog open={showAddField} onClose={() => setShowAddField(false)}>
      <DialogContent>

      </DialogContent>
    </Dialog>
    <Card>
      <CardContent>
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example">{tabs.map((tab, k) =>
            <Tab key={'subject_field_tab_' + k} label={tab.label} {...a11yProps(k)} />
          )}
          </Tabs>
        </AppBar>
        {tabs.map((tab, k) =>
          <TabPanel value={selectedTab} index={k} key={'subject_field_tab_panel_' + k}>
            <Grid container spacing={2}>
              {tab.fields.map((field, key) =>
                <Grid item xs={4}>
                  <TextField fullWidth label={field.label} key={'subject_field_tab_' + k + '_field_' + key}
                             value={field.value}/>
                </Grid>
              )}
            </Grid>
          </TabPanel>
        )}
      </CardContent>
      <CardActions>
        <Grid container spacing={2} justifyContent={"flex-end"}>
          <Grid item hidden={1}>
            {/* TODO Need to be able to save data for fields */}
            <Button color="primary" variant="outlined" onClick={() => setShowAddField(true)}>Add field</Button>
          </Grid>
          <Grid item>
            {/* TODO Need to be able to save data for fields */}
            <Button color="primary" variant="contained">Save</Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  </>);
}