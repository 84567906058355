import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {Input} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function SubjectFieldEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_fields.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "category",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_fields.edit.label.category", defaultMessage: "Category"}),
            input: "text"
        },
        {
            field: "import_key",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "subject_fields.edit.label.import_key", defaultMessage: "Import Key"}),
            input: "text"
        },
        {
            field: "shared",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "subject_fields.edit.label.shared", defaultMessage: "Shared"}),
            input: "switch"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Input color="primary"/>}
                        title={intl.formatMessage({id: "subject_fields.edit.heading", defaultMessage:"Subject Fields edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"subjectFields"}
                                         mutations={"subjectField"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
