import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Score} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function AssignmentOutcomeList(props) {
    const intl = useIntl();
    let assignmentId = props.match.params && props.match.params.assignmentID;

    const columns = [
        {
            title: intl.formatMessage({id:'assignment_outcomes.list.column.outcome.name', defaultMessage:'Outcome'}),
            field: 'outcome.name',
            type: 'string',
            filtering: false,
            width: 'auto'
        },
        {title: intl.formatMessage({id:'assignment_outcomes.list.column.score', defaultMessage:'Score'}), field: 'score', type: 'numeric'},
        {title: intl.formatMessage({id:'assignment_outcomes.list.column.hour_value', defaultMessage:'Hour value'}), field: 'hour_value', type: 'numeric'}
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'assignment_outcomes.list.table.heading', defaultMessage:"Assignment Outcomes list"})}
          columns={columns}
          query="assignmentOutcomes"
          mutations="assignmentOutcome"
          fields='id assignment{name} outcome{name} score hour_value'
          sorting='assignment.id'
          direction='desc'
          filter={'assignment_id:'+assignmentId}
          icon={<Score/>}
          {...props}
        />
      </Paper>
    );
}