import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Business} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import CustomerContactList from "./CustomerContactList";

export default function CustomerEdit(props) {
    const intl = useIntl();

    let id = props.match.params.id;

    const fields = [
        {
            column: 1,
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            column: 1,
            field: "address",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.address", defaultMessage: "Address"}),
            input: "text"
        },
        {
            column: 1,
            field: "zip",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.zip", defaultMessage: "Zip"}),
            input: "text"
        },
        {
            column: 1,
            field: "city",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.city", defaultMessage: "City"}),
            input: "text"
        },
        {
            column: 1,
            field: "country_code",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.country_code", defaultMessage: "Country"}),
            input: "country",
            inputCode: "Alpha-2"
        },
        {
            column: 1,
            field: "language_code",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.language_code", defaultMessage: "Language"}),
            input: "text"
        },
        {
            column: 1,
            field: "phone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.phone", defaultMessage: "Phone"}),
            input: "text"
        },
        {
            column: 1,
            field: "email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customers.edit.label.email", defaultMessage: "Email"}),
            input: "email"
        },
        {
            column: 1,
            field: "contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "customers.edit.label.contact_id", defaultMessage: "Contact"}),
            query: "customerContacts",
            filter: "customer_id:" + id,
            titleField: "name",
        },
        {
            column: 1,
            field: "reference",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.reference", defaultMessage: "Reference"}),
            input: "text"
        },
        {
            column: 1,
            field: "vat_number",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.vat_number", defaultMessage: "Vat Number"}),
            input: "text"
        },
        {
            column: 1,
            field: "ean_number",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.ean_number", defaultMessage: "Ean Number"}),
            input: "text"
        },
        {
            column: 2,
            input: "heading",
            label: intl.formatMessage({id: "customers.edit.heading.billing", defaultMessage: "Billing information"}),
        },
        {
            column: 2,
            field: "billing_email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customers.edit.label.billing_email", defaultMessage: "Billing Email"}),
            input: "email"
        },
        {
            column: 2,
            field: "billing_external_id",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.billing_external_id",
                defaultMessage: "Billing External Id"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "billing_reference",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.billing_reference",
                defaultMessage: "Billing Reference"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "last_billed_at",
            initial: null,
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.last_billed_at", defaultMessage: "Last Billed At"}),
            input: "datetime"
        },
        {
            column: 2,
            field: "external_id",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customers.edit.label.external_id", defaultMessage: "External Id"}),
            input: "text"
        },
        {
            column: 2,
            input: "heading",
            label: intl.formatMessage({id: "customers.edit.heading.mail_server", defaultMessage: "Mail server setup"}),
        },
        {
            column: 2,
            field: "mailserver_login",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_login",
                defaultMessage: "Login"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "mailserver_password",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_password",
                defaultMessage: "Password"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "mailserver_host",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_host",
                defaultMessage: "Hostname"
            }),
            input: "text"
        },
        {
            column: 2,
            field: "mailserver_port",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "customers.edit.label.mailserver_port", defaultMessage: "Port"}),
            input: "number"
        },
        {
            column: 2,
            field: "mailserver_ssl",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({
                id: "customers.edit.label.mailserver_ssl",
                defaultMessage: "Encryption"
            }),
            input: "switch"
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Business color="primary"/>}
                        title={id ? intl.formatMessage({id: "customers.edit.heading", defaultMessage: "Edit customer"}) :
                            intl.formatMessage({id: "customers.create.heading", defaultMessage: "Create customer"})}
                        titleTypographyProps={{color: "primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm
                            {...props}
                            id={id}
                            query={"customers"}
                            mutations={"customer"}
                            fields={fields}
                            cols={2}
                        />
                    </CardContent>
                </Card>
            </Grid>
            {id && <Grid item xs={12}>
                <CustomerContactList
                  {...props}
                  customerId={id}
                />
            </Grid>}
        </Grid>
    );
}
