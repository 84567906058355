import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Business} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function SubjectList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id: 'subjects.list.column.name', defaultMessage: 'Name'}), field: 'name'},
        {
            title: intl.formatMessage({id: 'subjects.list.column.address_1', defaultMessage: 'Address 1'}),
            field: 'address_1'
        },
        {title: intl.formatMessage({id: 'subjects.list.column.zip', defaultMessage: 'Zip'}), field: 'zip'},
        {title: intl.formatMessage({id: 'subjects.list.column.city', defaultMessage: 'City'}), field: 'city'},
        {
            title: intl.formatMessage({id: 'subjects.list.column.main_phone', defaultMessage: 'Phone'}),
            field: 'main_phone'
        },
        {
            title: intl.formatMessage({id: 'subjects.list.column.direct_phone', defaultMessage: 'Direct'}),
            field: 'direct_phone'
        },
        {
            title: intl.formatMessage({id: 'subjects.list.column.mobile_phone', defaultMessage: 'Mobile'}),
            field: 'mobile_phone'
        },
        {
            title: intl.formatMessage({id: 'subjects.list.column.email', defaultMessage: 'Email'}),
            field: 'email',
            type: 'string'
        },
        {
            title: intl.formatMessage({id: 'subjects.list.column.contacts_count', defaultMessage: 'Contacts'}),
            field: 'subject_contacts_count',
            type: 'number'
        }
    ];

    return (
        <Paper>
            <EnhancedTable
                softDeletes={true}
                title={intl.formatMessage({id: 'subjects.list.table.heading', defaultMessage: "Subjects list"})}
                columns={columns}
                query="subjects"
                mutations="subject"
                fields='id name address_1 zip city main_phone direct_phone mobile_phone email subject_contacts_count'
                icon={<Business/>}
                {...props}
            />
        </Paper>
    );
}