import { Button, ButtonGroup, Chip, Grid, Paper } from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import EnhancedTable from 'components/DataTable/EnhancedTable'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { authUser } from 'contexts/Auth'
import hexToRgb from '../../../assets/theme/hex-to-rgb'
import themeColors from '../../../assets/theme/colors'

export default function AssignmentList(props) {
  const intl = useIntl();
  const tableRef = useRef();
  const [filter, setFilter] = useState('active');
  const [queryFilter, setQueryFilter] = useState((props.filter ? props.filter : '') + ' is_active:true');

  const columns = [
    {title: intl.formatMessage({id: 'assignments.list.column.name', defaultMessage: 'Name'}), field: 'name'},
    {
      title: intl.formatMessage({id: 'assignments.list.column.customer.name', defaultMessage: 'Customer'}),
      field: 'customer.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.contact.name', defaultMessage: 'Contact'}),
      field: 'contact.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.manager.name', defaultMessage: 'Manager'}),
      field: 'manager.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.consultants', defaultMessage: 'Consultants'}),
      render: row => row.consultants.map(c => <Chip size="small" label={c.name}/>),
      sortable: false
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.subjects_handled_today_count', defaultMessage: 'Handled today'}),
      field: 'subjects_handled_today_count',
      sortable: false
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.non_invoiced_subjects_count', defaultMessage: 'Non invoiced'}),
      field: 'non_invoiced_subjects_count',
      sortable: false
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.closed_subjects_count', defaultMessage: 'Closed subjects'}),
      field: 'closed_subjects_count',
      sortable: false
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.open_subjects_count', defaultMessage: 'Open subjects'}),
      field: 'open_subjects_count',
      sortable: false
    },
    {
      title: intl.formatMessage({id: 'assignments.list.column.created_at', defaultMessage: 'Created'}),
      field: 'created_at',
      type: 'datetime'
    },
  ];

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    if (tableRef.current) tableRef.current.update();
    let f = '';
    switch (newFilter) {
      default:
      case 'active':
        f = (props.filter ? props.filter : '') + ' is_active:true';
        break;
      case 'passive':
        f = (props.filter ? props.filter : '') + ' is_active:false';
        break;
      case 'others':
        f = 'manager_id_ne:' + authUser().id + ' is_active:true';
        break;
    }
    setQueryFilter(f);
  }

  return (
    <Paper>
      <EnhancedTable
        {...props}
        ref={tableRef}
        softDeletes={true}
        title={<Grid container spacing={4}>
          <Grid item>{intl.formatMessage({id: 'assignments.list.table.heading', defaultMessage: "Assignments"})}</Grid>
          <Grid item><ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
            <Button variant={filter === 'active' ? "contained" : "outlined"}
                    onClick={() => handleFilterChange('active')}>{intl.formatMessage({id: 'assignments.list.table.filter.active', defaultMessage: "Active"})}</Button>
            <Button variant={filter === 'passive' ? "contained" : "outlined"}
                    onClick={() => handleFilterChange('passive')}>{intl.formatMessage({id: 'assignments.list.table.filter.passive', defaultMessage: "Passive"})}</Button>
            {props.filter &&
              <Button variant={filter === 'others' ? "contained" : "outlined"} onClick={() => handleFilterChange('others')}>{intl.formatMessage({id: 'assignments.list.table.filter.others', defaultMessage: "Others"})}</Button>}
          </ButtonGroup></Grid>
        </Grid>}
        columns={columns}
        query="assignments"
        mutations={props.filter ? '' : 'assignment'}
        fields='id name customer{name} contact{name} manager{name} consultants{name} subjects_handled_today_count non_invoiced_subjects_count closed_subjects_count open_subjects_count marked_subjects_count created_at'
        icon={<Assignment/>}
        filter={queryFilter}
        rowStyle={row => (
          {
            backgroundColor: (row.marked_subjects_count > 0)
              ? 'rgba(' + hexToRgb(themeColors.primary.main) + ', 0.2)'
              : 'inherit'
          }
        )}
        actions={[
          {
            icon: Assignment,
            rowClick: (props.filter !== undefined),
            tooltip: intl.formatMessage({id: "assignments.list.action.handle", defaultMessage: "Choose"}),
            onClick: (row) => {
              if (typeof props.onSelect === "function") props.onSelect(row);
              props.history.push('/assignment/' + row.id + '/subjects');
            },
          }
        ]}
      />
    </Paper>
  );
}