import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Person} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function SubjectContactList(props) {
    const intl = useIntl();
    const columns = [
        {
            title: intl.formatMessage({id:'subject_contacts.list.column.subject.name', defaultMessage:'Subject Id'}), 
            field: 'subject.name', 
            type: 'string', 
            filtering: false,
            width: 'auto'
        },
        {title: intl.formatMessage({id:'subject_contacts.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.phone', defaultMessage:'Phone'}), field: 'phone'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.mobile', defaultMessage:'Mobile'}), field: 'mobile'},
        {title: intl.formatMessage({id:'subject_contacts.list.column.email', defaultMessage:'Email'}), field: 'email', type: 'string'}
    ];

    if (props.subjectId) {
        columns.shift();
    }

    return (
      <Paper>
        <EnhancedTable
          softDeletes={true}
          title={intl.formatMessage({id:'subject_contacts.list.table.heading', defaultMessage:"Contacts"})}
          columns={columns}
          query="subjectContacts"
          mutations="subjectContact"
          fields='id subject{name} name title phone mobile email'
          filter={props.subjectId ? "subject_id:" + props.subjectId : ''}
          baseUrl={props.subjectId ? props.history.location.pathname + '/contact' : null}
          icon={<Person/>}
          {...props}
        />
      </Paper>
    );
}