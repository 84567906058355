import {Backdrop, Button, Card, CardContent, CardHeader, Dialog, DialogContent, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Add, Edit, Mail} from "@material-ui/icons";
import EditForm from "components/Form/EditForm";
import {useAuthDispatch} from "contexts/Auth";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {graphQLApi, graphQLReduceFields} from "services/GraphQLApi";
import AssignmentEmailTemplateEdit from "views/Assignment/Email/AssignmentEmailTemplateEdit";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function AssignmentEmailSettingEdit(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  
  const fields = [
    {
      column: 1,
      field: "subject_sender_name",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.subject_sender_name",
        defaultMessage: "Subject sender name"
      }),
      input: "text"
    },
    {
      column: 1,
      field: "subject_sender_email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.subject_sender_email",
        defaultMessage: "Subject sender email"
      }),
      input: "email"
    },
    {
      column: 1,
      field: 'subject_template{id}',
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('subject_template', key, data)
    },
    {
      column: 2,
      field: "report_sender_name",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.report_sender_name",
        defaultMessage: "Report sender name"
      }),
      input: "text"
    },
    {
      column: 2,
      field: "report_sender_email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.report_sender_email",
        defaultMessage: "Report sender email"
      }),
      input: "email"
    },
    {
      column: 2,
      field: "report_receiver_email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.report_receiver_email",
        defaultMessage: "Report receiver email"
      }),
      input: "email"
    },
    {
      column: 2,
      field: "report_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('report_template', key, data)
    },
    {
      column: 3,
      field: "confirmation_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('confirmation_template', key, data)
    },
    {
      column: 3,
      field: "direct_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('direct_template', key, data)
    },
    {
      column: 3,
      field: "alternative_1_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('alternative_1_template', key, data)
    },
    {
      column: 3,
      field: "alternative_2_template{id}",
      initial: null,
      label: '',
      render: (key, data) => getTemplateEditButton('alternative_2_template', key, data)
    },
    {
      fullWidth: true,
      field: "phoner_information",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "assignment_email_settings.edit.label.phoner_information",
        defaultMessage: "Phoner information"
      }),
      input: "html"
    },
    {
      fullWidth: true,
      field: "signature",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_email_settings.edit.label.signature", defaultMessage: "Signature"}),
      input: "html"
    },
  ];

  const [data, setData] = useState(graphQLReduceFields(fields));
  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);

  const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: (errors) => {
      if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
        setValidation({...initialValidation, ...errors[0].extensions.validation});
      }
    }});

  let assignmentId = props.match.params && props.match.params.assignmentID;

  useEffect(() => {
    if (assignmentId) {
      setIsLoading(true);
      client.query('{response : assignmentEmailSettings(filter:{assignment_id:' + assignmentId + '}){data{' +
        'id subject_sender_name subject_sender_email report_sender_name report_sender_email report_receiver_email subject_template{id} report_template{id} confirmation_template{id} direct_template{id} alternative_1_template{id} alternative_2_template{id} phoner_information signature ' +
        '}}}')
        .then(result => {
          if (result.response.data) {
            setData(result.response.data[0]);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  const TYPE_SUBJECT = 'subject_template';
  const TYPE_REPORT = 'report_template';
  const TYPE_CONFIRMATION = 'confirmation_template';
  const TYPE_DIRECT = 'direct_template';
  const TYPE_ALT_1 = 'alternative_1_template';
  const TYPE_ALT_2 = 'alternative_2_template';

  const getTemplateEditButton = (type, key, data) => {
    let label = "";
    let action = data[type] ?
      intl.formatMessage({id: "action.edit", defaultMessage: "Edit"}) :
      intl.formatMessage({id: "action.create", defaultMessage: "Create"});
    switch (type) {
      default:
        break;
      case TYPE_SUBJECT:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.subject_template",
            defaultMessage: "{action} Subject Template"
          }, {action: action}
        );
        break;
      case TYPE_REPORT:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.report_template",
            defaultMessage: "{action} Report Template"
          }, {action: action}
        );
        break;
      case TYPE_CONFIRMATION:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.confirmation_template",
            defaultMessage: "{action} Confirmation Template"
          }, {action: action}
        );
        break;
      case TYPE_DIRECT:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.direct_template",
            defaultMessage: "{action} Direct Template"
          }, {action: action}
        );
        break;
      case TYPE_ALT_1:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.alternate_1_template",
            defaultMessage: "{action} Alternative 1 Template"
          }, {action: action}
        );
        break;
      case TYPE_ALT_2:
        label = intl.formatMessage({
            id: "assignment_email_settings.edit.label.alternate_2_template",
            defaultMessage: "{action} Alternative 2 Template"
          }, {action: action}
        );
        break;
    }
    return (
      <Button
        disabled={!data || !data.id}
        container
        fullWidth
        variant="outlined"
        key={"subject_template_" + key}
        startIcon={data[type] ? <Edit/> : <Add/>}
        onClick={() => setTemplate({id: data[type] ? data[type].id : 0, type: type})}
      >
        {label}
      </Button>
    );
  }

  const handleSaving = (settings) => {
    const variables = {
      subject_sender_name: 'String',
      subject_sender_email: 'String',
      report_sender_name: 'String',
      report_sender_email: 'String',
      report_receiver_email: 'String',
      subject_template_id: 'ID',
      report_template_id: 'ID',
      confirmation_template_id: 'ID',
      direct_template_id: 'ID',
      alternative_1_template_id: 'ID',
      alternative_2_template_id: 'ID',
      phoner_information: 'String',
      signature: 'String',
      mailserver_host: 'String',
      mailserver_port: 'Int',
      mailserver_ssl: 'Int',
      mailserver_login: 'String',
      mailserver_password: 'String'
    };
    if (data.id) {
      variables.id = 'ID!';
    }
    console.log(data, settings, variables);
    setIsLoading(true);
    client.mutation('assignmentEmailSetting', variables, settings, "id")
      .then(r => {
        if (r.response) {
          setData(cur => ({...cur, id: r.response.id}));
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Grid container>
      <Backdrop className={classes.backdrop} open={isLoading}/>
      <Dialog open={template !== null} onClose={() => setTemplate(null)} maxWidth="md">
        <DialogContent>
          <AssignmentEmailTemplateEdit
            id={template ? template.id : 0}
            settingsId={data ? data.id : null}
            onSave={templateData => {
              setData({...data, [template.type]: templateData})
              setTemplate(null);
            }}
            buttons={[{
              label: intl.formatMessage({id: "common.button.close", defaultMessage: "Close"}),
              onClick: () => setTemplate(null)
            }]}/>
        </DialogContent>
      </Dialog>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={<Mail color="primary"/>}
            title={intl.formatMessage({
              id: "assignment_email_settings.edit.heading",
              defaultMessage: "Assignment Email Settings edit"
            })}
            titleTypographyProps={{color: "primary"}}
          />
          <CardContent>
            <EditForm
              fields={fields}
              cols={3}
              validation={validation}
              isLoading={isLoading}
              data={data}
              setData={setData}
              save={handleSaving}
              {...props}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
