import {
  AccountTree,
  Assignment,
  AssignmentInd,
  Business,
  Domain,
  Flare,
  Group,
  Home as HomeIcon,
  Input,
  Language,
  Person,
  Settings
} from '@material-ui/icons'
import AssignmentEdit from 'views/Admin/Assignments/AssignmentEdit'
import AssignmentList from 'views/Admin/Assignments/AssignmentList'
import CustomerContactEdit from 'views/Admin/Customers/CustomerContactEdit'
import CustomerContactList from 'views/Admin/Customers/CustomerContactList'
import CustomerEdit from 'views/Admin/Customers/CustomerEdit'
import CustomerList from 'views/Admin/Customers/CustomerList'
import Dashboard from 'views/Admin/Dashboard'
import DomainEdit from 'views/Admin/MutliSite/DomainEdit'
import SiteEdit from 'views/Admin/MutliSite/SiteEdit'
import SiteList from 'views/Admin/MutliSite/SiteList'
import DepartmentEdit from 'views/Admin/Settings/DepartmentEdit'
import DepartmentList from 'views/Admin/Settings/DepartmentList'
import LanguageEdit from 'views/Admin/Settings/LanguageEdit'
import LanguageList from 'views/Admin/Settings/LanguageList'
import OutcomeEdit from 'views/Admin/Settings/OutcomeEdit'
import OutcomeList from 'views/Admin/Settings/OutcomeList'
import RoleEdit from 'views/Admin/Settings/RoleEdit'
import RoleList from 'views/Admin/Settings/RoleList'
import SubjectContactEdit from 'views/Admin/Subjects/SubjectContactEdit'
import SubjectContactList from 'views/Admin/Subjects/SubjectContactList'
import SubjectEdit from 'views/Admin/Subjects/SubjectEdit'
import SubjectFieldEdit from 'views/Admin/Subjects/SubjectFieldEdit'
import SubjectFieldList from 'views/Admin/Subjects/SubjectFieldList'
import SubjectList from 'views/Admin/Subjects/SubjectList'
import UserEdit from 'views/Admin/Users/UserEdit'
import UserList from 'views/Admin/Users/UserList'

import { rolePriorities } from 'config'

export default function routes(intl) {
  const prefix = '/admin';
  return [
    {
      path: "",
      exact: true,
      name: intl.formatMessage({
        id: "menu.system_admin",
        defaultMessage: "System",
      }),
      icon: HomeIcon,
      component: Dashboard,
    },
    {
      path: "/sites",
      name: intl.formatMessage({
        id: "menu.sites.list",
        defaultMessage: "Sites",
      }),
      icon: Domain,
      component: SiteList,
      allow_priority: rolePriorities.site_manager,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.sites.create",
            defaultMessage: "Create Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/create",
          name: intl.formatMessage({
            id: "menu.domains.create",
            defaultMessage: "Create Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/:id",
          name: intl.formatMessage({
            id: "menu.domains.edit",
            defaultMessage: "Edit Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.sites.edit",
            defaultMessage: "Edit Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
      ],
    },
    {
      path: "/assignments",
      name: intl.formatMessage({id: "menu.assignments", defaultMessage: "Assignments"}),
      icon: Assignment,
      component: AssignmentList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.assignments.create", defaultMessage: "Create Assignment"}),
          component: AssignmentEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.assignments.edit", defaultMessage: "Edit Assignment"}),
          component: AssignmentEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/customers",
      name: intl.formatMessage({id: "menu.customers.list", defaultMessage: "Customers"}),
      icon: Business,
      component: CustomerList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/:customerId/consultant/create",
          name: intl.formatMessage({id: "menu.customer_contacts.create", defaultMessage: "Create Consultant"}),
          component: CustomerContactEdit,
          hidden: true,
        },
        {
          path: "/:customerId/consultant/:id",
          name: intl.formatMessage({id: "menu.customer_contacts.edit", defaultMessage: "Edit Consultant"}),
          component: CustomerContactEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.customers.create", defaultMessage: "Create Customer"}),
          component: CustomerEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.customers.edit", defaultMessage: "Edit Customer"}),
          component: CustomerEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/consultants",
      name: intl.formatMessage({id: "menu.customer_contacts.list", defaultMessage: "Consultants"}),
      icon: Group,
      component: CustomerContactList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.customer_contacts.create", defaultMessage: "Create Consultant"}),
          component: CustomerContactEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.customer_contacts.edit", defaultMessage: "Edit Consultant"}),
          component: CustomerContactEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/subjects",
      name: intl.formatMessage({id: "menu.subjects.list", defaultMessage: "Subjects"}),
      icon: Business,
      component: SubjectList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/:subjectId/contact/create",
          name: intl.formatMessage({id: "menu.subject_contacts.create", defaultMessage: "Create Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
        {
          path: "/:subjectId/contact/:id",
          name: intl.formatMessage({id: "menu.subject_contacts.edit", defaultMessage: "Edit Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.subjects.create", defaultMessage: "Create Subject"}),
          component: SubjectEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.subjects.edit", defaultMessage: "Edit Subject"}),
          component: SubjectEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/contacts",
      name: intl.formatMessage({id: "menu.subject_contacts.list", defaultMessage: "Contacts"}),
      icon: Person,
      component: SubjectContactList,
      roles: ["sysdev","admin"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.subject_contacts.create", defaultMessage: "Create Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.subject_contacts.edit", defaultMessage: "Edit Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/settings",
      name: intl.formatMessage({
        id: "menu.settings",
        defaultMessage: "Settings",
      }),
      icon: Settings,
      roles: ["sysdev", "admin", "sites_manager"],
      views: [
        {
          path: "/subjects/subject_fields",
          name: intl.formatMessage({id: "menu.subject_fields.list", defaultMessage: "Subject Fields"}),
          icon: Input,
          component: SubjectFieldList,
          roles: ["sysdev","admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.subject_fields.create", defaultMessage: "Create Subject Field"}),
              component: SubjectFieldEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.subject_fields.edit", defaultMessage: "Edit Subject Field"}),
              component: SubjectFieldEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/outcomes",
          name: intl.formatMessage({id: "menu.outcomes.list", defaultMessage: "Outcomes"}),
          icon: Flare,
          component: OutcomeList,
          roles: ["sysdev","admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.outcomes.create", defaultMessage: "Create Outcome"}),
              component: OutcomeEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.outcomes.edit", defaultMessage: "Edit Outcome"}),
              component: OutcomeEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/departments",
          name: intl.formatMessage({id: "menu.departments.list", defaultMessage: "Departments"}),
          icon: AccountTree,
          component: DepartmentList,
          roles: ["sysdev","admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.departments.create", defaultMessage: "Create Department"}),
              component: DepartmentEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.departments.edit", defaultMessage: "Edit Department"}),
              component: DepartmentEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/languages",
          name: intl.formatMessage({id: "menu.languages.list", defaultMessage: "Languages"}),
          icon: Language,
          component: LanguageList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.languages.create", defaultMessage: "Create Language"}),
              component: LanguageEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.languages.edit", defaultMessage: "Edit Language"}),
              component: LanguageEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/roles",
          name: intl.formatMessage({id: "menu.roles.list", defaultMessage: "Roles"}),
          icon: AssignmentInd,
          component: RoleList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.roles.create", defaultMessage: "Create Role"}),
              component: RoleEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.roles.edit", defaultMessage: "Edit Role"}),
              component: RoleEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/users",
          name: intl.formatMessage({
            id: "menu.users.list",
            defaultMessage: "Users",
          }),
          icon: Person,
          component: UserList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({
                id: "menu.users.create",
                defaultMessage: "Create user",
              }),
              component: UserEdit,
              hidden: true
            },
            {
              path: "/:id",
              name: intl.formatMessage({
                id: "menu.users.edit",
                defaultMessage: "Edit user",
              }),
              component: UserEdit,
              hidden: true
            },
          ],
        },
      ]
    },
  ].map(r => ({...r, path: prefix + r.path}));
}









