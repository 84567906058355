import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import componentStyles from 'assets/theme/layouts/admin.js'
import AdminFooter from 'components/Footers/AdminFooter.js'
// @material-ui/icons components
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import { authUser } from 'contexts/Auth'
import React from 'react'
import { useIntl } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import { config } from 'config'

const useStyles = makeStyles(componentStyles);

export default function SinglePage({history, match, routes}) {
  const intl = useIntl();
  const classes = useStyles();
  const brandText = intl.formatMessage({id:"navbar.brand_text"});

  const getRoutes = (routes, prefix = "") => {
    return routes.map((prop, key) => {
      let rs = [];
      if (prop.views) {
        rs = getRoutes(prop.views, prefix + prop.path);
      }
      rs.push(<Route
        path={!prop.shared ? prefix + prop.path : prop.path}
        component={prop.component}
        key={key}
        exact={prop.exact}
      />);
      return rs;
    });
  };

  return (
    <>
      <>
        <Box position="relative" className={classes.mainContentNoSidebar}>
          <AdminNavbar routes={routes} history={history} match={match} logo={{
            innerLink: routes[0].path,
            imgSrc: (authUser().site && authUser().site.logo_uri)
              ? authUser().site.logo_uri
              : config.brandLogo.default,
            imgAlt: brandText,
          }}/>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Container classes={{ root: classes.mainRoot }}>
              <Switch>
                {getRoutes(routes)}
              </Switch>
            </Container>
            <AdminFooter />
          </Container>
        </Box>
      </>
    </>
  );
};
