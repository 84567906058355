import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import ExitToApp from "@material-ui/icons/ExitToApp";
import Person from "@material-ui/icons/Person";

// core components
import componentStyles from "assets/theme/components/navbar-dropdown.js";
import {config, getLayouts} from "config";
import {authSignOut, authUser, useAuthDispatch} from "contexts/Auth";
import React from "react";
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(componentStyles);

export default function NavbarDropdown(props) {
  const dispatch = useAuthDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();
  const intl = useIntl();

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleMenuClose();
    props.history.push('/user-profile');
  };

  // const handleLogOut = () => {
  //   handleMenuClose();
  //   authSignOut(dispatch);
  // };

  const getLayoutLinks = () => {
    let links = [];
    const layouts = getLayouts(intl);
    for (let key in layouts) {
      if (layouts[key].hidden) continue;
      if (layouts[key].authenticated && authUser().isAllowed(layouts[key].acl)) {
        links.push(layouts[key]);
      }
    }
    if (links.length === 1) return [];
    return links;
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography variant="h6" component="h6" classes={{ root: classes.menuTitle }}>{authUser().name}</Typography>
      {config.layoutNavigation === "context" && getLayoutLinks().map((layout, k) =>
        <div key={"layout_links_" + k}>
          <Box
            display="flex!important"
            alignItems="center!important"
            component={MenuItem}
            onClick={() => history.push(layout.defaultRoute)}
          >
            <Box
              component={layout.icon}
              width="1.25rem!important"
              height="1.25rem!important"
              marginRight="1rem"
            />
            <span>{layout.title}</span>
          </Box>
          <Divider component="div" classes={{ root: classes.dividerRoot }} />
        </div>
      )}
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleProfile}
      >
        <Box
          component={Person}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>{intl.formatMessage({id: 'navbar.dropdown.my_profile', defaultMessage: 'My profile'})}</span>
      </Box>
      {/*<Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={Settings}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Settings</span>
      </Box>
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={EventNote}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Activity</span>
      </Box>
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={LiveHelp}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Support</span>
      </Box>*/}
      <Divider component="div" classes={{ root: classes.dividerRoot }} />
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={() => authSignOut(dispatch)}
      >
        <Box
          component={ExitToApp}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>{intl.formatMessage({id: 'navbar.dropdown.logout', defaultMessage: 'Logout'})}</span>
      </Box>
    </Menu>
  );

  return (
    <>
      <Button
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
      >
        <Avatar
          alt={authUser().name}
          classes={{
            root: classes.avatarRoot,
          }}
        >{authUser().name.toUpperCase().split(" ").map(w => w.substring(0, 1)).join("")}</Avatar>
        <Hidden smDown>{authUser().name}</Hidden>
      </Button>
      {renderMenu}
    </>
  );
}
