import React from "react";
import {useIntl} from "react-intl";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function AssignmentEmailTemplateEdit(props) {
  const intl = useIntl();

  const fields = [
    {
      column: 1,
      field: "cc_email",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_email_templates.edit.label.cc_email", defaultMessage: "CC"}),
      input: "email"
    },
    {
      column: 2,
      field: "bcc_email",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_email_templates.edit.label.bcc_email", defaultMessage: "BCC"}),
      input: "email"
    },
    {
      fullWidth: true,
      field: "subject",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_email_templates.edit.label.subject", defaultMessage: "Subject"}),
      input: "text"
    },
    {
      fullWidth: true,
      field: "body",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "assignment_email_templates.edit.label.body", defaultMessage: "Body"}),
      input: "html"
    }
  ];

  return (
    <GraphQLEditForm id={props.id}
                     query={"assignmentEmailTemplates"}
                     mutations={"assignmentEmailTemplate"}
                     fields={fields}
                     cols={2}
                     onSave={props.onSave}
                     buttons={props.buttons}
    />
  );
}
