import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Input} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function SubjectFieldList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'subject_fields.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'subject_fields.list.column.category', defaultMessage:'Category'}), field: 'category'},
        {title: intl.formatMessage({id:'subject_fields.list.column.import_key', defaultMessage:'Import Key'}), field: 'import_key'},
        {title: intl.formatMessage({id:'subject_fields.list.column.shared', defaultMessage:'Shared'}), field: 'shared', type: 'boolean', initial: false},
        {title: intl.formatMessage({id:'subject_fields.list.column.usage', defaultMessage:'Assignment usage'}), field: 'assignments_count', type: 'number', initial: 0, sortable: false},
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'subject_fields.list.table.heading', defaultMessage:"Subject Fields list"})}
          columns={columns}
          query="subjectFields"
          mutations="subjectField"
          fields='id name category import_key shared assignments_count'
          icon={<Input/>}
          {...props}
        />
      </Paper>
    );
}