import {Assignment, DashboardSharp, ListAlt, Mail, People, Score, Settings} from "@material-ui/icons";
import AssignmentEdit from "views/Admin/Assignments/AssignmentEdit";
import Dashboard from "views/Assignment/Dashboard";
import AssignmentEmailSettingEdit from "views/Assignment/Email/AssignmentEmailSettingEdit";
import AssignmentOutcomeEdit from "views/Assignment/Outcomes/AssignmentOutcomeEdit";
import AssignmentOutcomeList from "views/Assignment/Outcomes/AssignmentOutcomeList";
import AssignmentSubjectEdit from "views/Assignment/Subjects/AssignmentSubjectEdit";
import AssignmentSubjectList from "views/Assignment/Subjects/AssignmentSubjectList";
import {rolePriorities} from "../config";
import SubjectFieldsEdit from "views/Assignment/SubjectFields/SubjectFieldsEdit";
import SubjectFieldsList from "views/Assignment/SubjectFields/SubjectFieldsList";
import SubjectContactEdit from "views/Admin/Subjects/SubjectContactEdit";

export default function routes(intl, prefix) {
  return [
    {
      path: "",
      exact: true,
      name: intl.formatMessage({id: "menu.assignment.dashboard", defaultMessage: "Dashboard"}),
      icon: DashboardSharp,
      component: Dashboard,
      query: 'assignments(filter:{id:#assignmentID#}) {data{name customer{name}}}',
      queryResult: (result) => {
        return result.assignments.data[0].name;
      }
    },
    {
      path: "/subjects",
      name: intl.formatMessage({id: "menu.assignment_subjects.list", defaultMessage: "Subjects"}),
      icon: People,
      component: AssignmentSubjectList,
      allow_priority: rolePriorities.manager,
      views: [
        {
          path: "/:subjectId/contact/create",
          name: intl.formatMessage({id: "menu.subject_contacts.create", defaultMessage: "Create Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
        {
          path: "/:subjectId/contact/:id",
          name: intl.formatMessage({id: "menu.subject_contacts.edit", defaultMessage: "Edit Contact"}),
          component: SubjectContactEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.assignment_subjects.create", defaultMessage: "Create Subject"}),
          component: AssignmentSubjectEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.assignment_subjects.edit", defaultMessage: "Edit Subject"}),
          component: AssignmentSubjectEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/settings",
      exact: true,
      name: intl.formatMessage({id: "menu.assignment.settings", defaultMessage: "Settings"}),
      icon: Settings,
      allow_priority: rolePriorities.manager,
      views: [
        {
          path: "/assignment",
          icon: Assignment,
          name: intl.formatMessage({id: "menu.assignment.settings.generel", defaultMessage: "Generel"}),
          component: AssignmentEdit,
        },
        {
          path: "/email",
          icon: Mail,
          name: intl.formatMessage({id: "menu.assignment.settings.email", defaultMessage: "Email"}),
          component: AssignmentEmailSettingEdit,
        },
        {
          path: "/outcomes",
          name: intl.formatMessage({id: "menu.assignment_outcomes.list", defaultMessage: "Outcomes"}),
          icon: Score,
          component: AssignmentOutcomeList,
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.assignment_outcomes.create", defaultMessage: "Create Outcome"}),
              component: AssignmentOutcomeEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.assignment_outcomes.edit", defaultMessage: "Edit Outcome"}),
              component: AssignmentOutcomeEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/fields",
          icon: ListAlt,
          name: intl.formatMessage({id: "menu.assignment.settings.fields.list", defaultMessage: "Fields"}),
          component: SubjectFieldsList,
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.assignment.settings.fields.create", defaultMessage: "Create Outcome"}),
              component: SubjectFieldsEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.assignment.settings.fields.edit", defaultMessage: "Edit Outcome"}),
              component: SubjectFieldsEdit,
              hidden: true,
            },
          ]
        },
      ]
    },
  ].map(r => ({...r, path: prefix + r.path}));
}
