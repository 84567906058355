import {config, getLayouts} from "config";
import SinglePage from "layouts/SinglePage";
import React from "react";
import {useIntl} from "react-intl";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import UsersProfile from "views/UsersProfile";
import {authUser, useAuthState} from "./Auth";

export default function Layouts() {
  let { isAuthenticated } = useAuthState();
  const intl = useIntl();

  const getLayoutRoutes = () => {
    let routes = [];
    let defaultRoute = config.defaultRoute;
    const layouts = getLayouts(intl);
    if (isAuthenticated) {
      routes.push(<Route
        key={"user-profile"}
        path="/user-profile"
        render={(props) => <SinglePage path={"/user-profile"} routes={[
          {
            path: "/user-profile",
            component: UsersProfile,
            hidden: true,
          }
        ]} {...props}/>}
      />);
    }
    for (let key in layouts) {
      let layout = layouts[key];
      if ((!isAuthenticated && !layout.authenticated) || (isAuthenticated && layout.authenticated && authUser().isAllowed(layout.acl))) {
        routes.push(
          <Route
            key={"layouts-" + key}
            path={layout.path}
            render={(props) =>
              <layout.component path={layout.path} routes={layout.routes} {...props}/>
            }
          />
        )
        if (!defaultRoute && layout.defaultRoute) defaultRoute = layout.defaultRoute;
      }
    }
    if (defaultRoute) {
      routes.push(<Route
        key={"default-route"}
        path="/"
        render={() => <Redirect to={defaultRoute}/>}
      />);
    }
    return routes;
  }

  return <BrowserRouter>
    <Switch>
      {getLayoutRoutes()}
    </Switch>
  </BrowserRouter>;
}