import { Assignment, PhoneEnabled, Settings } from '@material-ui/icons'
import { authUser } from 'contexts/Auth'
import SinglePage from 'layouts/SinglePage'
import assignmentRoutes from 'routes/assignment'
import dialerRoutes from 'routes/dialer'
import Admin from './layouts/Admin'
import Auth from './layouts/Auth'

export const rolePriorities = {
  developer: 5,
  site_manager: 10,
  admin: 25,
  manager: 50,
  sales: 75,
  accounting: 100,
  lead: 125,
  phoner: 150,
};

export const roles = (intl) => [
    {id: 5, name: intl.formatMessage({id: "roles.priority.5", defaultMessage: "Is system developer"})},
    {id: 10, name: intl.formatMessage({id: "roles.priority.10", defaultMessage: "Can edit sites"})},
    {id: 25, name: intl.formatMessage({id: "roles.priority.25", defaultMessage: "Can edit settings"})},
    {id: 50, name: intl.formatMessage({id: "roles.priority.50", defaultMessage: "Can edit users"})},
    {id: 125, name: intl.formatMessage({id: "roles.priority.150", defaultMessage: "Can edit data"})},
    {id: 150, name: intl.formatMessage({id: "roles.priority.175", defaultMessage: "Can view data"})},
  ];

export const features = {}
// const hasFeature = (feature) => this.features.findIndex(f => f.key === feature) !== -1;

export function getLayouts(intl) {
  if (authUser().roles.length === 1 && authUser().roles[0].key === "sites_manager") {
    return {
      auth: {
        title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
        authenticated: false,
        component: Auth,
        acl: null,
        features: [],
        path: "/",
        defaultRoute: "/login"
      },
      admin: {
        title: intl.formatMessage({id: "layouts.admin", defaultMessage: "Administration"}),
        icon: Settings,
        authenticated: true,
        component: Admin,
        acl: rolePriorities.site_manager,
        features: [],
        path: "/admin",
        defaultRoute: "/admin/sites"
      },
    }
  }
  return {
    auth: {
      title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
      authenticated: false,
      component: Auth,
      acl: null,
      features: [],
      path: "/",
      defaultRoute: "/login"
    },
    admin: {
      title: intl.formatMessage({id: "layouts.admin", defaultMessage: "System admin"}),
      icon: Settings,
      authenticated: true,
      component: Admin,
      acl: rolePriorities.admin,
      features: [],
      path: "/admin",
      defaultRoute: "/admin"
    },
    assignment: {
      title: intl.formatMessage({id: "layouts.assignment", defaultMessage: "Administration"}),
      icon: Assignment,
      authenticated: true,
      component: Admin,
      acl: 50,
      features: [],
      path: "/assignment/:assignmentID?",
      defaultRoute: "/assignment",
      routes: assignmentRoutes(intl, '/assignment/:assignmentID?'),
      // hidden: true,
    },
    dialer: {
      title: intl.formatMessage({id: "layouts.dialer", defaultMessage: "Dialer"}),
      icon: PhoneEnabled,
      authenticated: true,
      component: SinglePage,
      acl: 125,
      features: [],
      path: "/dialer",
      defaultRoute: "/dialer",
      routes: dialerRoutes(intl)
    },
  };
}

export const config = {
  brandLogo: require("assets/img/brand/tele-mark-logo.svg"),
  emailVerification: true,
  layoutNavigation: "context",  // context = in the user profile context menu, horisontal = top navbar with no breadcrumb
  viewNavigation: "left",       // left = left vertical, top = top horisontal instead of breadcrumb
  auth: {
    register: false,
    rememberMe: false,
    passwordReset: true,
  },
  admin: {}
}
