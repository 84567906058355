import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { Person } from '@material-ui/icons'
import { graphQLApi, graphQLReduceFields } from 'services/GraphQLApi'
import { authRefresh, authUser, useAuthDispatch } from 'contexts/Auth'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import EditForm from '../../../components/Form/EditForm'

const useStyles = makeStyles({
    tableCell: {
        borderTop: 0,
        borderBottom: 0,
    }
});

export default function UserEdit(props) {
    const classes = useStyles();
    const intl = useIntl();
    let id = Number(props.match.params.id);

    /**
     * Notification
     */
    const [notification, setNotification] = React.useState({
        severity: 'info',
        show: false,
        message: '',
    });
    const notify = (message, color = 'info') => {
        setNotification({severity: color, message: message, show: true});
    }
    const closeNotification = () => setNotification({...notification, show: false});

    const fields = [
        {
            column: 1,
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "user.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            column: 1,
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "user.edit.label.name", defaultMessage: "Full Name"}),
            input: "text"
        },
        {
            column: 1,
            field: "email",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "user.edit.label.email", defaultMessage: "E-mail"}),
            input: "text"
        },
        {
            column: 1,
            field: "phone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "user.edit.label.phone", defaultMessage: "Phone"}),
            input: "text"
        },
        {
            column: 2,
            field: "last_login_at",
            initial: "",
            type: "String",
            input: "datetime",
            disabled: () => true,
            label: intl.formatMessage({id: "user.edit.label.last_login_at", defaultMessage: "Last login was"}),
        },
        {
            column: 2,
            field: "email_verified_at",
            initial: "",
            type: "String",
            input: "datetime",
            disabled: () => true,
            label: intl.formatMessage({id: "user.edit.label.email_verified_at", defaultMessage: "Email was verified the"}),
        },
        {
            column: 2,
            field: "created_at",
            initial: "",
            type: "String",
            input: "datetime",
            disabled: () => true,
            label: intl.formatMessage({id: "user.edit.label.created_at", defaultMessage: "User was created the"}),
        },
        {
            column: 2,
            input: "heading",
            label: id ?
              intl.formatMessage({id: "user.edit.label.change_password", defaultMessage: "Change password"}) :
              intl.formatMessage({id: "user.edit.label.set_password", defaultMessage: "Set password"}),
        },
        {
            column: 2,
            field: "password",
            initial: "",
            type: "String",
            label: id ?
              intl.formatMessage({id: "user.edit.label.new_password", defaultMessage: "New password"}) :
              intl.formatMessage({id: "user.edit.label.password", defaultMessage: "Password"})
            ,
            input: "password",
        },
        {
            column: 2,
            field: "password_confirmation",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "user.edit.label.password_confirm", defaultMessage: "Confirm password"}),
            input: "password",
        },
    ];

    const [user, setUser] = useState({...graphQLReduceFields(fields, 'initial'), roles:[]});
    const [roles, setRoles] = useState([]);

    const isRoleSelected = (id) => user.roles.find((v) => v.id === id) !== undefined;
    const handleClick = (event, id) => {
        if (isRoleSelected(id)) {
            setUser(cur => {console.log('Remove role', id, cur.roles, cur.roles.filter(r => r !== id)); return {...cur, roles: cur.roles.filter(r => r.id !== id)}});
        } else {
            setUser(cur => ({...cur, roles: [...cur.roles, {id:id}]}));
        }
    };

    const initialValidation = graphQLReduceFields(fields, 'validation');
    const [validation, setValidation] = useState(initialValidation);
    const setValidationFromErrors = (errors) => {
        if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
            setValidation({...initialValidation, ...errors[0].extensions.validation});
        }
    };
    const [isLoading, setIsLoading] = useState(false);
    const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});
    const stableClient = useCallback(client, []);
    useEffect(() => {
        let query = "roles {data{ id title }}";
        if (id) {
            query =
                "users(filter:{id:" +
                id +
                "}) { data { id title name email phone roles{id} email_verified_at created_at last_login_at} } " +
                query;
        }
        setIsLoading(true);
        stableClient
            .query("{" + query + "}")
            .then((result) => {
                if (result.users) {
                    result.users.data[0].organisation_id = result.users.data[0].organisation;
                    setUser(result.users.data[0]);
                }
                setRoles(result.roles.data);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error("Caught exception", e);
            });
    }, [id, stableClient]);

    const save = () => {
        setIsLoading(true);
        setValidation(initialValidation);
        let data = {...user};
        let variables = {
            title: "String",
            name: "String!",
            email: "String!",
            phone: "String",
            password: "String",
            password_confirmation: "String",
            roles: "[ID]",
        };
        if (id) {
            variables.id = "ID!";
            data.id = id;
        }
        client.mutation('user', variables, data, "id").then((result) => {
            setIsLoading(false);
            if (result && result.response) {
                if (Number(authUser().id) === Number(result.response.id)) {
                    authRefresh(true, true);
                }
                notify(intl.formatMessage({
                    id: "user.edit.alert.success",
                    defaultMessage: "User was successfully saved!",
                }), "succes");
                if (isNaN(id)) {
                    props.history.replace(props.history.location.pathname.replace("create", result.response.id));
                }
            }
        });
    };

    return (<Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
                <Snackbar
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                  }}
                  message={notification.message}
                  style={{backgroundColor: notification.severity}}
                  open={notification.show}
                  onClose={closeNotification}
                  autoHideDuration={6000}
                />
                <Card>
                    <CardHeader avatar={<Person color="primary"/>} title={intl.formatMessage({id:"user.edit.table.profile.header", defaultMessage:"User profile"})}
                                titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <EditForm
                          data={user}
                          setData={setUser}
                          isLoading={isLoading}
                          save={save}
                          validation={validation}
                          fields={fields}
                        />
                    </CardContent>
                </Card>
            </Grid>
            {authUser().isAllowed(50) ? (
                <Grid item xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader
                            avatar={<Person color="primary"/>}
                            title={intl.formatMessage({id:"user.edit.table.roles.header", defaultMessage:"Roles assigned to the user"})}
                            titleTypographyProps={{color:"primary"}}
                        />
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                    {roles.map((row, index) => {
                                        const isItemSelected = isRoleSelected(row.id);

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index+"-"+row.id}
                                            >
                                                <TableCell className={classes.tableCell} padding="checkbox">
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name={"role-" + row.id}
                                                                color={"primary"}
                                                                checked={isItemSelected}
                                                                onChange={(event) => handleClick(event, row.id)}
                                                            />}
                                                            label={row.title}
                                                        />
                                                    </FormGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            ) : (
                ""
            )}
        </Grid>
    );
}
