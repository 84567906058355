import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import {Assessment, CallToAction, Close, CloudDownload, Group, Mail, People, Warning} from '@material-ui/icons'
import EnhancedTable from 'components/DataTable/EnhancedTable'
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import themeColors from 'assets/theme/colors'
import theme from 'assets/theme/theme'
import hexToRgb from 'assets/theme/hex-to-rgb'
import {graphQLApi} from 'services/GraphQLApi'
import {useAuthDispatch} from 'contexts/Auth'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";

export default function AssignmentSubjectList(props) {
  const intl = useIntl()
  let assignmentId = props.match.params && props.match.params.assignmentID
  const columns = [
    {
      title: intl.formatMessage({id: 'assignment_subjects.list.column.subject.name', defaultMessage: 'Subject'}),
      field: 'subject.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({id: 'assignment_subjects.list.column.outcome.name', defaultMessage: 'Outcome'}),
      field: 'outcome.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({id: 'assignment_subjects.list.column.phoner.name', defaultMessage: 'Phoner'}),
      field: 'phoner.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({
        id: 'assignment_subjects.list.column.customer_contact.name',
        defaultMessage: 'Consultant'
      }),
      field: 'customer_contact.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({
        id: 'assignment_subjects.list.column.next_contact_at',
        defaultMessage: 'Next contact at'
      }),
      field: 'next_contact_at',
      type: 'datetime',
    },
    {
      title: intl.formatMessage({
        id: 'assignment_subjects.list.column.meeting_established_at',
        defaultMessage: 'Meeting established at'
      }),
      field: 'meeting_established_at',
      type: 'datetime',
    },
    {
      title: intl.formatMessage({id: 'assignment_subjects.list.column.status.name', defaultMessage: 'Status'}),
      field: 'status.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({
        id: 'assignment_subjects.list.column.subject_contact.name',
        defaultMessage: 'Contact'
      }),
      field: 'subject_contact.name',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({
        id: 'assignment_subjects.list.column.contact_title',
        defaultMessage: 'Contact title'
      }), field: 'contact_title'
    },
    {
      title: intl.formatMessage({id: 'assignment_subjects.list.column.contact_name', defaultMessage: 'Contact name'}),
      field: 'contact_name'
    },
    {
      title: intl.formatMessage({id: 'assignment_subjects.list.column.calls_made', defaultMessage: 'Calls made'}),
      field: 'calls_made'
    },
    {
      title: intl.formatMessage({
        id: 'assignment_subjects.list.column.last_edited_by_manager_at',
        defaultMessage: 'Edited by manager'
      }),
      field: 'last_edited_by_manager_at',
      type: 'datetime',
    },
    {
      title: intl.formatMessage({
        id: 'assignment_subjects.list.column.last_edited_by_phoner_at',
        defaultMessage: 'Edited by phoner'
      }),
      field: 'last_edited_by_phoner_at',
      type: 'datetime',
    },
  ]

  /**
   *  Outcomes filter handling
   **/
  const [outcomes, setOutcomes] = useState([]);
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);
  const handleOutcomeClick = (checked, outcomeId) => {
    let selection = [...selectedOutcomes];
    if (checked) {
      selection.push(outcomeId);
    } else {
      selection = selection.filter(id => id !== outcomeId);
    }
    setSelectedOutcomes(selection);
  }
  const handleOutcomeButtonClick = (isClosed) => {
    const ids = outcomes.filter(o => o.is_closed === isClosed).map(o => o.id);
    const selected = ids.filter(id => selectedOutcomes.includes(id));
    if (selected.length) {
      setSelectedOutcomes(cur => cur.filter(id => !ids.includes(id)));
    } else {
      setSelectedOutcomes(cur => [...cur, ...ids.filter(id => !cur.includes(id))]);
    }
  }

  const [options, setOptions] = useState({
    phoner_id: [],
    customer_contact_id: [],
    status_id: [],
    outcome_id: [],
    past_status_id: [],
    field: []
  });
  const [data, setData] = useState({
    next_contact_at: '',
    imported: '',
    last_edited_by_phoner_at: '',
    last_edited_by_manager_at: '',
    meeting_established_at: '',
    phoner_id: '',
    customer_contact_id: '',
    status_id: '',
    past_status_id: '',
    name: '',
    website: '',
    telephone: '',
    address: '',
    city: '',
    business_type: '',
    zip_from: '',
    zip_to: '',
    employees: '',
    field: '',
    field_value: '',
  });
  const [operators, setOperators] = useState({
    next_contact_at: ['date', 'gt_date', 'gte_date', 'lt_date', 'lte_date', 'ne_date'],
    imported: ['gt_date', 'gte_date', 'lt_date', 'lte_date', 'date', 'ne_date'],
    last_edited_by_phoner_at: ['gt_date', 'gte_date', 'lt_date', 'lte_date', 'date', 'ne_date'],
    last_edited_by_manager_at: ['gt_date', 'gte_date', 'lt_date', 'lte_date', 'date', 'ne_date'],
    meeting_established_at: ['gt_date', 'lt_date', 'date', 'ne_date'],
    zip_from: ['=', 'gt', 'gte', 'ne'],
    zip_to: ['lt', 'lte'],
    employees: ['gt', 'gte', 'lt', 'lte', '='],
    field_value: ['like', 'not_like'],
  });
  const labels = {
    next_contact_at: 'Næste kontakt',
    imported: 'Importeret',
    last_edited_by_phoner_at: 'Berørt phoner',
    last_edited_by_manager_at: 'Berørt leder',
    meeting_established_at: 'Møde booket',
    phoner_id: 'Phoner',
    customer_contact_id: 'Konsulent',
    status_id: 'Nuværende status',
    past_status_id: 'Har haft status',
    name: 'Firma navn',
    website: 'Website',
    telephone: 'Telephone',
    address: 'Adresse',
    city: 'By',
    business_type: 'Branche',
    zip_from: 'Postnr.',
    zip_to: 'Postnr. til',
    employees: 'Ansatte',
    field: 'Extra felt',
    field_value: 'Extra felt værdi',
  };
  const getOperatorIcon = (operator) => {
    switch (operator) {
      case 'lt':
        return <Tooltip title={"Mindre"}><Icon className="fa fa-less-than" fontSize="small"/></Tooltip>;
      case 'lte':
        return <Tooltip title={"Mindre eller samme"}><Icon className="fa fa-less-than-equal"
                                                           fontSize="small"/></Tooltip>;
      case 'gt':
        return <Tooltip title={"Større"}><Icon className="fa fa-greater-than" fontSize="small"/></Tooltip>;
      case 'gte':
        return <Tooltip title={"Større eller samme"}><Icon className="fa fa-greater-than-equal"
                                                           fontSize="small"/></Tooltip>;
      case 'ne':
        return <Tooltip title={"Forskellig"}><Icon className="fa fa-not-equal" fontSize="small"/></Tooltip>;
      case 'lt_date':
        return <Tooltip title={"Er før dagen"}><Icon className="fa fa-less-than" fontSize="small"/></Tooltip>;
      case 'lte_date':
        return <Tooltip title={"På dagen eller før"}><Icon className="fa fa-less-than-equal"
                                                           fontSize="small"/></Tooltip>;
      case 'gt_date':
        return <Tooltip title={"Er efter dagen"}><Icon className="fa fa-greater-than" fontSize="small"/></Tooltip>;
      case 'gte_date':
        return <Tooltip title={"På dagen eller efter"}><Icon className="fa fa-greater-than-equal"
                                                             fontSize="small"/></Tooltip>;
      case 'ne_date':
        return <Tooltip title={"Ikke denne dag"}><Icon className="fa fa-not-equal" fontSize="small"/></Tooltip>;
      case 'date':
        return <Tooltip title={"På denne dag"}><Icon className="fa fa-equals" fontSize="small"/></Tooltip>;
      case '=':
        return <Tooltip title={"Lig med"}><Icon className="fa fa-equals" fontSize="small"/></Tooltip>;
      case 'like':
        return <Tooltip title={"Indeholder"}><Icon className="fa fa-equals" fontSize="small"/></Tooltip>;
      case 'not_like':
        return <Tooltip title={"Indeholder ikke"}><Icon className="fa fa-not-equal" fontSize="small"/></Tooltip>;
      default:
        return <Warning/>;
    }
  }
  const switchOperator = (operator) => {
    let newO = {...operators};
    let o = newO[operator].shift();
    newO[operator].push(o);
    setOperators(newO);
  }

  const [showExportFieldSelect, setShowExportFieldSelect] = useState(false);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  /**
   * Initial filter information load from API
   **/
  const tableRef = useRef();
  const [filter, setFilter] = useState('assignment_id:' + assignmentId);
  useEffect(() => {
    let newFilter = ['assignment_id:' + assignmentId];
    for (let k in data) {
      let v = data[k];
      if (!v) continue;
      if (!/^[\d.]+$/.test(v)) v = '"' + v + '"';
      let c = k;
      if (operators.hasOwnProperty(k)) {
        if (operators[k][0] === 'not_like') {
          c += '_ne';
        } else if (!['=', 'like'].includes(operators[k][0])) {
          c += '_' + operators[k][0];
        }
      }
      newFilter.push(c + ':' + v);
    }
    if (selectedOutcomes.length) {
      newFilter.push('outcome_ids:[' + selectedOutcomes.join(',') + ']');
    }
    setFilter(newFilter.join(' '));
  }, [data, selectedOutcomes, operators]);
  const [isLoading, setIsLoading] = useState(false);
  const client = new graphQLApi(useAuthDispatch(), props.history)
  useEffect(() => {
    setIsLoading(true);
    client.query('{' +
      'assignmentOutcomes(filter:{assignment_id:' + assignmentId + '}){data{outcome{id name is_closed is_target}}}' +
      'assignmentSubjectStatuses{data{id name}}' +
      'assignmentSubjectFields(filter:{assignment_id:' + assignmentId + '}){data{id name}}' +
      'assignments(filter:{id:' + assignmentId + '}){data{phoners{id name email} consultants{id name} outcomes{outcome{id name is_closed}}}}' +
      '}').then(r => {
      setIsLoading(false);
      if (!r) return
      let newOptions = {...options};
      if (r.assignmentOutcomes) {
        setOutcomes(r.assignmentOutcomes.data.map(ao => ao.outcome));
      }
      if (r.assignmentSubjectFields) {
        newOptions.field = r.assignmentSubjectFields.data;
        let sort = 0;
        setAvailableColumns([
          {id:'id', name:intl.formatMessage({id:'export_subjects.columns.id', defaultMessage:'Assigned ID'}), sorting:sort++},
          {id:'assignment', name:intl.formatMessage({id:'export_subjects.columns.id', defaultMessage:'Assignment'}), sorting:sort++},
          {id:'outcome', name:intl.formatMessage({id:'export_subjects.columns.outcome', defaultMessage:'Outcome'}), sorting:sort++},
          {id:'phoner', name:intl.formatMessage({id:'export_subjects.columns.phoner', defaultMessage:'Phoner'}), sorting:sort++},
          {id:'customer_contact', name:intl.formatMessage({id:'export_subjects.columns.customer_contact', defaultMessage:'Consultant'}), sorting:sort++},
          {id:'next_contact_at', name:intl.formatMessage({id:'export_subjects.columns.next_contact_at', defaultMessage:'Next Contact'}), sorting:sort++},
          {id:'last_edited_by_manager_at', name:intl.formatMessage({id:'export_subjects.columns.last_edited_by_manager_at', defaultMessage:'Edited by Manager'}), sorting:sort++},
          {id:'last_edited_by_phoner_at', name:intl.formatMessage({id:'export_subjects.columns.last_edited_by_phoner_at', defaultMessage:'Edited by Phoner'}), sorting:sort++},
          {id:'meeting_established_at', name:intl.formatMessage({id:'export_subjects.columns.meeting_established_at', defaultMessage:'Meeting Established'}), sorting:sort++},
          {id:'calls_made', name:intl.formatMessage({id:'export_subjects.columns.calls_made', defaultMessage:'Calls Made'}), sorting:sort++},
          {id:'status', name:intl.formatMessage({id:'export_subjects.columns.status', defaultMessage:'Status'}), sorting:sort++},
          {id:'contact_title', name:intl.formatMessage({id:'export_subjects.columns.contact_title', defaultMessage:'Contact Title'}), sorting:sort++},
          {id:'contact_name', name:intl.formatMessage({id:'export_subjects.columns.contact_name', defaultMessage:'Contact Name'}), sorting:sort++},
          {id:'contact_email', name:intl.formatMessage({id:'export_subjects.columns.contact_email', defaultMessage:'Contact Email'}), sorting:sort++},
          {id:'contact_phone', name:intl.formatMessage({id:'export_subjects.columns.contact_phone', defaultMessage:'Contact Phone'}), sorting:sort++},
          {id:'contact_department', name:intl.formatMessage({id:'export_subjects.columns.contact_department_id', defaultMessage:'Contact Department'}), sorting:sort++},
          {id:'subject_id', name:intl.formatMessage({id:'export_subjects.columns.subject_id', defaultMessage:'Unique ID'}), sorting:sort++},
          {id:'subject_name', name:intl.formatMessage({id:'export_subjects.columns.subject_name', defaultMessage:'Name'}), sorting:sort++},
          {id:'subject_address_1', name:intl.formatMessage({id:'export_subjects.columns.subject_address_1', defaultMessage:'Address'}), sorting:sort++},
          {id:'subject_address_2', name:intl.formatMessage({id:'export_subjects.columns.subject_address_2', defaultMessage:'Address 2'}), sorting:sort++},
          {id:'subject_zip', name:intl.formatMessage({id:'export_subjects.columns.subject_zip', defaultMessage:'Zip'}), sorting:sort++},
          {id:'subject_city', name:intl.formatMessage({id:'export_subjects.columns.subject_city', defaultMessage:'City'}), sorting:sort++},
          {id:'subject_state', name:intl.formatMessage({id:'export_subjects.columns.subject_state', defaultMessage:'State'}), sorting:sort++},
          {id:'subject_country_code', name:intl.formatMessage({id:'export_subjects.columns.subject_country_code', defaultMessage:'Country code'}), sorting:sort++},
          {id:'subject_main_phone', name:intl.formatMessage({id:'export_subjects.columns.subject_main_phone', defaultMessage:'Main Phone'}), sorting:sort++},
          {id:'subject_direct_phone', name:intl.formatMessage({id:'export_subjects.columns.subject_direct_phone', defaultMessage:'Direct Phone'}), sorting:sort++},
          {id:'subject_mobile_phone', name:intl.formatMessage({id:'export_subjects.columns.subject_mobile_phone', defaultMessage:'Mobile Phone'}), sorting:sort++},
          {id:'subject_email', name:intl.formatMessage({id:'export_subjects.columns.subject_email', defaultMessage:'Email'}), sorting:sort++},
          {id:'subject_website', name:intl.formatMessage({id:'export_subjects.columns.subject_website', defaultMessage:'Website'}), sorting:sort++},
          {id:'subject_registration_number', name:intl.formatMessage({id:'export_subjects.columns.subject_registration_number', defaultMessage:'Registration Number'}), sorting:sort++},
          {id:'subject_business_type', name:intl.formatMessage({id:'export_subjects.columns.subject_business_type', defaultMessage:'Business Type'}), sorting:sort++},
          {id:'subject_employees', name:intl.formatMessage({id:'export_subjects.columns.subject_employees', defaultMessage:'Employees'}), sorting:sort++},
          {id:'subject_notes', name:intl.formatMessage({id:'export_subjects.columns.subject_notes', defaultMessage:'Notes'}), sorting:sort++},
          {id:'subject_import_key', name:intl.formatMessage({id:'export_subjects.columns.subject_import_key', defaultMessage:'Import Key'}), sorting:sort++},
          ...r.assignmentSubjectFields.data.map((c,i) => ({...c, sorting:i+sort})),
        ]);
      }
      if (r.assignmentSubjectStatuses) {
        newOptions.status_id = r.assignmentSubjectStatuses.data;
        newOptions.past_status_id = r.assignmentSubjectStatuses.data;
      }
      if (r.assignments && r.assignments.data) {
        newOptions.phoner_id = r.assignments.data[0].phoners ? r.assignments.data[0].phoners : [];
        newOptions.customer_contact_id = r.assignments.data[0].consultants ? r.assignments.data[0].consultants : [];
        newOptions.outcome_id = r.assignments.data[0].outcomes ? r.assignments.data[0].outcomes.map(o => o.outcome) : [];
      }
      setOptions(newOptions);
    })
  }, []);

  const [downloadAnchor, setDownloadAnchor] = useState();
  const [sendAnchor, setSendAnchor] = useState();
  const [subjectsAnchor, setSubjectsAnchor] = useState();
  const [actionsAnchor, setActionsAnchor] = useState();

  const [showAssignPhoner, setShowAssignPhoner] = useState(false);
  const handleSelectPhoner = (phoner) => {
    setIsLoading(true);
    setActionsAnchor(null);
    let selectedRows = tableRef.current.getSelected();
    client
      .mutate('{assignmentSubjectsUpdate(ids:[' + selectedRows.join(',') + '],phoner_id:' + phoner.id + '){id}}')
      .then(_r => {
        let rows = [...tableRef.current.getRows()];
        for (let i in rows) {
          if (selectedRows.includes(rows[i].id)) {
            rows[i].phoner = phoner;
          }
        }
        tableRef.current.setRows(rows);
        setIsLoading(false);
        setShowAssignPhoner(false);
      });
  }

  const [showAssignOutcome, setShowAssignOutcome] = useState(false);
  const [newOutcome, setNewOutcome] = useState();
  const [newStatus, setNewStatus] = useState();
  const handleSelectOutcome = () => {
    setIsLoading(true);
    setActionsAnchor(null);
    let selectedRows = tableRef.current.getSelected();
    let updates = [];
    if (newOutcome) {
      updates.push('outcome_id:' + newOutcome.id);
    }
    if (newStatus) {
      updates.push('status_id:' + newStatus.id);
    }
    client
      .mutate('{assignmentSubjectsUpdate(ids:[' + selectedRows.join(',') + '] ' + updates.join(' ') + '){id}}')
      .then(_r => {
        let rows = [...tableRef.current.getRows()];
        for (let i in rows) {
          if (selectedRows.includes(rows[i].id)) {
            if (newOutcome) {
              rows[i].outcome = options.outcome_id.find(o => o.id === newOutcome);
            }
            if (newStatus) {
              rows[i].status = options.status_id.find(s => s.id === newStatus);
            }
          }
        }
        tableRef.current.setRows(rows);
        setIsLoading(false);
        setShowAssignOutcome(false);
      });
  }

  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleDeleteSubjects = (confirmed) => {
    setConfirmDelete(false);
    setSubjectsAnchor(null);
    if (confirmed) {
      client
        .mutate('{assignmentSubjectsDelete(ids:[' + tableRef.current.getSelected().join(',') + '])}')
        .then(r => {
          if (r.assignmentSubjectsDelete) tableRef.current.update()
        })
    }
  }
  const ASSIGNMENT_DIALOG_HIDE = 0;
  const ASSIGNMENT_DIALOG_MODE_COPY = 1;
  const ASSIGNMENT_DIALOG_MODE_MOVE = 2;
  const [assignmentDialogMode, setAssignmentDialogMode] = useState(ASSIGNMENT_DIALOG_HIDE);
  const [assignmentDialogSelection, setAssignmentDialogSelection] = useState();
  const openAssignmentDialog = (mode) => {
    setAssignmentDialogSelection(null);
    setAssignmentDialogMode(mode);
  }
  const closeAssignmentDialog = () => {
    setAssignmentDialogMode(ASSIGNMENT_DIALOG_HIDE);
    setSubjectsAnchor(null);
  }
  const handleAssignmentDialogAction = () => {
    let mutation = "";
    closeAssignmentDialog();
    setIsLoading(true);
    switch (assignmentDialogMode) {
      case ASSIGNMENT_DIALOG_MODE_COPY:
        mutation = '{assignmentSubjectsUpdate(ids:[' + tableRef.current.getSelected().join(',') + '], copy_to_assignment_id:' + assignmentDialogSelection.id + '){id}}'
        break;
      case ASSIGNMENT_DIALOG_MODE_MOVE:
        mutation = '{assignmentSubjectsUpdate(ids:[' + tableRef.current.getSelected().join(',') + '], assignment_id:' + assignmentDialogSelection.id + '){id}}'
        break;
      default:
        return;
    }
    client.mutate(mutation).then(r => {
      if (r && r.assignmentSubjectsUpdate) {
        setIsLoading(false);
        tableRef.current.update();
      }
    });
  }

  const closeExportSubjectsDialog = () => {
    setShowExportFieldSelect(false);
    setAvailableColumns([...availableColumns, ...exportColumns]);
    setExportColumns([]);
  }

  const appendToExportedColumns = (column) => {
    const i = availableColumns.findIndex(c => c.id === column.id);
    const nEC = [...exportColumns];
    nEC.push(column);
    setExportColumns(nEC);
    const nAC = [...availableColumns];
    nAC.splice(i, 1);
    setAvailableColumns(nAC);
  }

  const returnToAvailableColumns = (column) => {
    const i = exportColumns.findIndex(c => c.id === column.id);
    const nAC = [...availableColumns];
    nAC.push(column);
    setAvailableColumns(nAC);
    const nEC = [...exportColumns];
    nEC.splice(i, 1);
    setExportColumns(nEC);
  }

  return (
    <Paper>
      <ConfirmDialog
        onClose={handleDeleteSubjects}
        open={confirmDelete}
        title={"Bekræft sletning af emner"}
        message={"Er du helt sikker på du vil slette de emnerne valgte emner fra opgaven?"}
      />
      <Grid container spacing={2} style={{margin: 0, width: '100%', padding: 4, position: "relative"}}>
        {isLoading && <Grid item xs={12} style={{
          textAlign: "center",
          position: "absolute",
          zIndex: 1001,
          width: "100%",
          height: 275,
          backgroundColor: 'rgba(' + hexToRgb(themeColors.white.main) + ', 0.8)'
        }}>
          <CircularProgress style={{marginTop: 110}}/>
        </Grid>}
        <Grid container item xs={4} spacing={1}>
          <Grid item xs={6}>
            <FormControl component="fieldset" style={{margin: theme.spacing(1)}} fullWidth>
              <FormLabel component="legend" style={{width: '100%', textAlign: 'center', paddingBottom: 6}}>
                <Button variant="contained" size="small"
                        onClick={() => handleOutcomeButtonClick(false)}>Alle/ingen</Button>
              </FormLabel>
              <FormGroup style={{
                flexWrap: 'nowrap',
                height: 200,
                overflow: 'auto',
                backgroundColor: 'rgba(' + hexToRgb(themeColors.success.main) + ', 0.2)',
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 4,
                borderColor: themeColors.gray[400],
              }}>
                {outcomes.filter(o => o.is_closed === false).map(o => <FormControlLabel
                  key={"outcome-" + o.id}
                  control={<Checkbox
                    name={"outcome-" + o.id}
                    size="small"
                    checked={!!selectedOutcomes.find(id => id === o.id)}
                    onChange={e => handleOutcomeClick(e.target.checked, o.id)}/>}
                  label={o.name}
                />)}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset" style={{margin: theme.spacing(1)}} fullWidth>
              <FormLabel component="legend" style={{width: '100%', textAlign: 'center', paddingBottom: 6}}>
                <Button variant="contained" size="small"
                        onClick={() => handleOutcomeButtonClick(true)}>Alle/ingen</Button>
              </FormLabel>
              <FormGroup style={{
                backgroundColor: 'rgba(' + hexToRgb(themeColors.error.main) + ', 0.2)',
                flexWrap: 'nowrap',
                height: 200,
                overflow: 'auto',
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 4,
                borderColor: themeColors.gray[400],
              }}>
                {outcomes.filter(o => o.is_closed === true).map(o => <FormControlLabel
                  key={"outcome-" + o.id}
                  control={<Checkbox
                    name={"outcome-" + o.id}
                    size="small"
                    checked={!!selectedOutcomes.find(id => id === o.id)}
                    onChange={e => handleOutcomeClick(e.target.checked, o.id)}/>}
                  label={o.name}
                />)}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={8} spacing={1}>
          <Grid item container xs={12} spacing={1}>
            {['next_contact_at', 'imported', 'last_edited_by_phoner_at', 'last_edited_by_manager_at', 'meeting_established_at'].map((v, k) =>
              <Grid item key={'dates-' + k} style={{width: '20%', overflow: 'clip'}}><TextField
                fullWidth
                size="small"
                label={labels[v]}
                type="date"
                name={'dates-' + v}
                value={data[v]}
                onChange={e => setData({...data, [v]: e.target.value})}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton size="small"
                                  onClick={() => switchOperator(v)}>{getOperatorIcon(operators[v][0])}</IconButton>
                    </InputAdornment>
                  ),
                }}/></Grid>)}
            {['phoner_id', 'customer_contact_id', 'status_id', 'past_status_id'].map(v =>
              <Grid item key={v} style={{width: '25%', overflow: 'clip'}}>
                <FormControl fullWidth>
                  <InputLabel htmlFor={v}>{labels[v]}</InputLabel>
                  <Select
                    inputProps={{
                      name: v,
                      id: v,
                    }}
                    value={data[v]}
                    onChange={e => setData({...data, [v]: e.target.value})}
                  >
                    <MenuItem value={''}>&nbsp;</MenuItem>
                    {options[v].map(p =>
                      <MenuItem key={v + '-' + p.id} value={p.id}>{p.name ? p.name : <i>{'ID: ' + p.id}</i>}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>)}
            {['name', 'website', 'telephone', 'address', 'city', 'business_type'].map(v =>
              <Grid item key={'data-' + v} xs={2} style={{overflow: 'clip'}}><TextField
                fullWidth
                size="small"
                label={labels[v]}
                name={v}
                value={data[v]}
                onChange={e => setData({...data, [v]: e.target.value})}
              /></Grid>)}
            {['zip_from', 'zip_to', 'employees'].map(v =>
              <Grid item xs={2} key={'data-' + v} style={{overflow: 'clip'}}><TextField
                fullWidth
                size="small"
                label={labels[v]}
                name={v}
                value={data[v]}
                onChange={e => setData({...data, [v]: e.target.value})}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton size="small"
                                  onClick={() => switchOperator(v)}>{getOperatorIcon(operators[v][0])}</IconButton>
                    </InputAdornment>
                  ),
                }}
              /></Grid>)}
            <Grid item xs={2} style={{overflow: 'clip'}}>
              <FormControl fullWidth>
                <InputLabel htmlFor="extra-field">{labels.field}</InputLabel>
                <Select
                  inputProps={{
                    name: 'field',
                    id: 'field',
                  }}
                  value={data.field}
                  onChange={e => setData({...data, field: e.target.value})}
                >
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  {options.field.map(sf => <MenuItem key={'subject-field-' + sf.id} value={sf.id}>{sf.name}</MenuItem>)}
                </Select>
              </FormControl></Grid>
            <Grid item xs={2} style={{overflow: 'clip'}}><TextField
              fullWidth
              name='field_value'
              label={labels.field_value}
              value={data.field_value}
              onChange={e => setData({...data, field_value: e.target.value})}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small"
                                onClick={() => switchOperator('field_value')}>{getOperatorIcon(operators.field_value[0])}</IconButton>
                  </InputAdornment>
                ),
              }}
            /></Grid>
            <Grid item xs={2}>
              <Button fullWidth variant="contained" color="primary" onClick={() => {
                if (tableRef.current) {
                  tableRef.current.update();
                }
              }}>Søg</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu id="tabel_menu_download" anchorEl={downloadAnchor} open={Boolean(downloadAnchor)}
            onClose={() => setDownloadAnchor(null)}>
        <MenuItem onClick={() => {
          setShowExportFieldSelect(true);
          setDownloadAnchor(null);
        }}>Tabel oversigt</MenuItem>
        <MenuItem disabled>Stamkort rapport</MenuItem>
      </Menu>
      <Menu id="tabel_menu_send" anchorEl={sendAnchor} open={Boolean(sendAnchor)} onClose={() => setSendAnchor(null)}>
        <MenuItem disabled>Tabel oversigt</MenuItem>
        <MenuItem disabled>Stamkort rapport</MenuItem>
        <MenuItem disabled>Kalender</MenuItem>
        <MenuItem disabled>Bekræftelser</MenuItem>
        <MenuItem disabled>Direct mail</MenuItem>
        <MenuItem disabled>Alternativ 1 mail</MenuItem>
        <MenuItem disabled>Alternativ 2 mail</MenuItem>
      </Menu>
      <Menu id="tabel_menu_subjects" anchorEl={subjectsAnchor} open={Boolean(subjectsAnchor)}
            onClose={() => setSubjectsAnchor(null)}>
        <MenuItem onClick={() => setConfirmDelete(true)}>Slet fra opgaven</MenuItem>
        <MenuItem onClick={() => openAssignmentDialog(ASSIGNMENT_DIALOG_MODE_COPY)}>Kopier til opgave</MenuItem>
        <MenuItem onClick={() => openAssignmentDialog(ASSIGNMENT_DIALOG_MODE_MOVE)}>Flyt til opgave</MenuItem>
      </Menu>
      <Menu id="tabel_menu_actions" anchorEl={actionsAnchor} open={Boolean(actionsAnchor)}
            onClose={() => setActionsAnchor(null)}>
        <MenuItem onClick={() => setShowAssignPhoner(true)}>Tildel phoner</MenuItem>
        <MenuItem onClick={() => setShowAssignOutcome(true)}>Skift udfald/status</MenuItem>
        <MenuItem disabled>Vis alle dubletter</MenuItem>
        <MenuItem disabled>Flyt alle dubletter</MenuItem>
        <MenuItem disabled>Sorter felt data</MenuItem>
      </Menu>
      <EnhancedTable
        {...props}
        ref={tableRef}
        title={intl.formatMessage({
          id: 'assignment_subjects.list.table.heading',
          defaultMessage: 'Search found {total} subjects'
        })}
        actions={[
          {
            label: 'Download', icon: CloudDownload, onSelected: true, onClick: (rows, e) => {
              setDownloadAnchor(e.currentTarget)
            }
          },
          {
            label: 'Send', icon: Mail, onSelected: true, onClick: (rows, e) => {
              setSendAnchor(e.currentTarget)
            }
          },
          {
            label: 'Emner', icon: Group, onSelected: true, onClick: (rows, e) => {
              setSubjectsAnchor(e.currentTarget)
            }
          },
          {
            label: 'Udfør', icon: CallToAction, onSelected: true, onClick: (rows, e) => {
              setActionsAnchor(e.currentTarget)
            }
          },
        ]}
        columns={columns}
        query="assignmentSubjects"
        mutations="assignmentSubject"
        filter={filter}
        fields="id subject{name} outcome{name is_closed} phoner{id name email} customer_contact{name} calls_made next_contact_at last_edited_by_manager_at last_edited_by_phoner_at meeting_established_at status{name} subject_contact{name} contact_title contact_name contact_email contact_phone contact_department{name}"
        icon={<People/>}
        searchable={false}
        rowStyle={row => ({backgroundColor: row.outcome.is_closed ? 'rgba(' + hexToRgb(themeColors.error.main) + ', 0.2)' : 'inherit'})}
      />
      <Dialog open={showAssignPhoner} onClose={() => {
        setShowAssignPhoner(false);
        setActionsAnchor(null);
      }}>
        <DialogTitle disableTypography>
          <Typography variant="h2">
            {intl.formatMessage({
              id: "assignment.subjects.dialog.change_phoner",
              defaultMessage: "Assign phoner to {count} subjects"
            }, {
              count: tableRef.current && tableRef.current.getSelected().length
            })}
          </Typography>
          <IconButton
            style={{position: 'absolute', right: theme.spacing(1), top: theme.spacing(1)}}
            onClick={() => {
              setShowAssignPhoner(false);
              setActionsAnchor(null);
            }}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer style={{maxHeight: 500}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Navn</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{options.phoner_id.map((p, k) =>
                <TableRow
                  key={'phoners-' + k}
                  hover
                  style={{cursor: "pointer"}}
                  onClick={() => handleSelectPhoner(p)}>
                  <TableCell>{p.name}</TableCell>
                  <TableCell>{p.email}</TableCell>
                </TableRow>
              )}</TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <Dialog open={showAssignOutcome} onClose={() => {
        setShowAssignOutcome(false);
        setActionsAnchor(null);
      }}>
        <DialogTitle disableTypography>
          <Typography variant="h2">
            {intl.formatMessage({
              id: "assignment.subjects.dialog.change_outcome_status",
              defaultMessage: "Change outcome/status on {count} subjects"
            }, {
              count: tableRef.current && tableRef.current.getSelected().length
            })}
          </Typography>
          <IconButton
            style={{position: 'absolute', right: theme.spacing(1), top: theme.spacing(1)}}
            onClick={() => {
              setShowAssignOutcome(false);
              setActionsAnchor(null);
            }}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl component="fieldset" fullWidth style={{
                padding: 4,
                backgroundColor: 'rgba(' + hexToRgb(themeColors.success.main) + ', 0.2)',
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 4,
                borderColor: themeColors.gray[400],
              }}>
                <FormLabel style={{padding: 4, fontWeight: "bold"}} component="legend">Åbne udfald</FormLabel>
                <RadioGroup aria-label="outcome" name="outcome" value={newOutcome}
                            onChange={e => setNewOutcome(e.target.value)}>
                  {options.outcome_id.filter(o => !o.is_closed).map((o, k) => <FormControlLabel key={'outcomes-' + k}
                                                                                                value={o.id}
                                                                                                control={<Radio/>}
                                                                                                label={o.name}/>)}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset" fullWidth style={{
                padding: 4,
                backgroundColor: 'rgba(' + hexToRgb(themeColors.error.main) + ', 0.2)',
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 4,
                borderColor: themeColors.gray[400],
              }}>
                <FormLabel style={{padding: 4, fontWeight: "bold"}} component="legend">Lukkede udfald</FormLabel>
                <RadioGroup aria-label="outcome" name="outcome" value={newOutcome}
                            onChange={e => setNewOutcome(e.target.value)}>
                  {options.outcome_id.filter(o => o.is_closed).map((o, k) => <FormControlLabel key={'outcomes-' + k}
                                                                                               value={o.id}
                                                                                               control={<Radio/>}
                                                                                               label={o.name}/>)}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" style={{
                padding: 4,
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 4,
                borderColor: themeColors.gray[400],
              }}>
                <FormLabel style={{padding: 4, fontWeight: "bold"}} component="legend">Status</FormLabel>
                <RadioGroup style={{flexFlow: 'row wrap'}} aria-label="status" name="status" value={newStatus}
                            onChange={e => setNewStatus(e.target.value)}>
                  {options.status_id.map((s, k) => <FormControlLabel style={{width: "30%"}} key={'outcomes-' + k}
                                                                     value={s.id} control={<Radio/>} label={s.name}/>)}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item container xs={12} justifyContent="space-between">
              <Button variant="outlined" color="primary" onClick={() => {
                setNewOutcome(null)
                setNewStatus(null)
              }}>Nulstil</Button>
              <Button variant="contained" color="primary" onClick={handleSelectOutcome}>Udfør</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={assignmentDialogMode > 0} onClose={closeAssignmentDialog} maxWidth={'lg'}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {assignmentDialogMode > 0 && <EnhancedTable
                icon={<Assessment/>}
                title={assignmentDialogMode === ASSIGNMENT_DIALOG_MODE_COPY
                  ? intl.formatMessage({
                    id: "assignment.subjects.dialog.copy_subjects",
                    defaultMessage: "Copy {count} subjects to assignment"
                  }, {
                    count: tableRef.current && tableRef.current.getSelected().length
                  })
                  : intl.formatMessage({
                    id: "assignment.subjects.dialog.move_subjects",
                    defaultMessage: "Move {count} subjects to assignment"
                  }, {
                    count: tableRef.current && tableRef.current.getSelected().length
                  })}
                query={'assignments'}
                fields={'id name customer{name} manager{name} subjects_count'}
                filter={'is_active:true,is_template:false'}
                columns={[
                  {
                    title: intl.formatMessage({id: 'assignments.list.column.name', defaultMessage: 'Name'}),
                    field: 'name'
                  },
                  {
                    title: intl.formatMessage({
                      id: 'assignments.list.column.customer.name',
                      defaultMessage: 'Customer'
                    }),
                    field: 'customer.name',
                    type: 'string',
                    filtering: false,
                    width: 'auto'
                  },
                  {
                    title: intl.formatMessage({id: 'assignments.list.column.manager.name', defaultMessage: 'Manager'}),
                    field: 'manager.name',
                    type: 'string',
                    filtering: false,
                    width: 'auto'
                  },
                  {
                    title: intl.formatMessage({
                      id: 'assignments.list.column.subjects_count',
                      defaultMessage: 'Subjects'
                    }),
                    field: 'subjects_count',
                    sortable: false
                  },
                ]}
                actions={[
                  {tooltip: "Vælg opgave", rowClick: true, onClick: row => setAssignmentDialogSelection(row)}
                ]}
                rowStyle={row => (
                  {
                    backgroundColor: (assignmentDialogSelection && assignmentDialogSelection.id === row.id)
                      ? 'rgba(' + hexToRgb(themeColors.primary.main) + ', 0.2)'
                      : 'inherit'
                  }
                )}
              />}
            </Grid>
            <Grid item container xs={12} justifyContent="space-between">
              <Button variant="outlined" color="primary" onClick={closeAssignmentDialog}>Luk</Button>
              <Button variant="contained" color="primary" onClick={handleAssignmentDialogAction}>Udfør</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={showExportFieldSelect} onClose={closeExportSubjectsDialog}>
        <DialogTitle disableTypography>
          <Typography variant="h2">Vælg de kolonner du vil have med i eksporten</Typography>
          <IconButton
            style={{position: 'absolute', right: theme.spacing(1), top: theme.spacing(1)}}
            onClick={closeExportSubjectsDialog}>
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <InputLabel shrink>Tilgængelige kolonner</InputLabel>
              <Paper style={{height:400, overflow:"auto"}}>
                <List dense component="div" role="list">
                  {availableColumns
                    .sort((a,b) => {if(a.sorting > b.sorting) return 1; if (a.sorting < b.sorting) return -1; return 0;})
                    .map((column) => {
                    const labelId = `transfer-list-item-${column.id}-label`;
                    return (
                      <ListItem key={column.id} role="listitem" button onClick={() => appendToExportedColumns(column)}>
                        <ListItemText id={labelId} primary={column.name}/>
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
            <Grid item container xs={2} justifyContent="space-around" alignItems="center" direction="column" wrap="nowrap">
              <IconButton onClick={() => {
                setAvailableColumns([...exportColumns, ...availableColumns]);
                setExportColumns([]);
              }}><FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon></IconButton>
              <IconButton onClick={() => {
                setExportColumns([...exportColumns, ...availableColumns]);
                setAvailableColumns([]);
              }}><FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon></IconButton>
            </Grid>
            <Grid item xs={5}>
              <InputLabel shrink>Eksporterede kolonner</InputLabel>
              <Paper style={{height:400, overflow:"auto"}}>
              <List dense component="div" role="list">
                {exportColumns.map((column) => {
                  const labelId = `transfer-list-item-${column.id}-label`;
                  return (
                    <ListItem key={column.id} role="listitem" button onClick={() => returnToAvailableColumns(column)}>
                      <ListItemText id={labelId} primary={column.name}/>
                    </ListItem>
                  );
                })}
              </List>
              </Paper>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button disabled={exportColumns.length < 1} variant="contained" color="primary">
                {intl.formatMessage({id:'assignment_subjects.download.button.export_subjects', defaultMessage:"Export subjects"})}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  )
}