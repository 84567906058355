import React from "react";
import {useIntl} from "react-intl";
import {AppBar, Box, Card, CardContent, CardHeader, Tab, Tabs} from "@material-ui/core";
import {Business} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import SubjectContactList from "./SubjectContactList";
import PropTypes from "prop-types";
import SubjectFieldForm from "components/Form/SubjectFieldForm";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={1}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function SubjectEdit(props) {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleTabChange = (_event, newTab) => {
    setSelectedTab(newTab);
  };

  let id = props.match.params.id;

  const fields = [
    {
      field: "name",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.name", defaultMessage: "Name"}),
      input: "text"
    },
    {
      field: "address_1",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.address_1", defaultMessage: "Address 1"}),
      input: "text"
    },
    {
      field: "address_2",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.address_2", defaultMessage: "Address 2"}),
      input: "text"
    },
    {
      field: "zip",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.zip", defaultMessage: "Zip"}),
      input: "text"
    },
    {
      field: "city",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.city", defaultMessage: "City"}),
      input: "text"
    },
    {
      field: "state",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.state", defaultMessage: "State"}),
      input: "text"
    },
    {
      field: "country_code",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.country_code", defaultMessage: "Country"}),
      input: "text"
    },
    {
      field: "main_phone",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.main_phone", defaultMessage: "Main Phone"}),
      input: "text"
    },
    {
      field: "direct_phone",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.direct_phone", defaultMessage: "Direct Phone"}),
      input: "text"
    },
    {
      field: "mobile_phone",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.mobile_phone", defaultMessage: "Mobile Phone"}),
      input: "text"
    },
    {
      field: "email",
      initial: "",
      type: "Email",
      label: intl.formatMessage({id: "subjects.edit.label.email", defaultMessage: "Email"}),
      input: "email"
    },
    {
      field: "website",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.website", defaultMessage: "Website"}),
      input: "text"
    },
    {
      field: "registration_number",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.registration_number", defaultMessage: "Registration Number"}),
      input: "text"
    },
    {
      field: "business_type",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.business_type", defaultMessage: "Business Type"}),
      input: "text"
    },
    {
      field: "employees",
      initial: 0,
      type: "Int",
      label: intl.formatMessage({id: "subjects.edit.label.employees", defaultMessage: "Employees"}),
      input: "number"
    },
    {
      field: "notes",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.notes", defaultMessage: "Notes"}),
      input: "text"
    },
    {
      field: "import_key",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "subjects.edit.label.import_key", defaultMessage: "Import Key"}),
      input: "text"
    }
  ];

  return (
    <>
      <AppBar position="static">
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label={intl.formatMessage({
            id: "subjects.edit.tab.general",
            defaultMessage: "General"
          })} {...a11yProps(0)} />
          <Tab
            label={intl.formatMessage({id: "subjects.edit.tab.fields", defaultMessage: "Fields"})} {...a11yProps(1)} />
          <Tab label={intl.formatMessage({
            id: "subjects.edit.tab.contacts",
            defaultMessage: "Contacts"
          })} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <Card>
          <CardHeader
            avatar={<Business color="primary"/>}
            title={intl.formatMessage({id: "subjects.edit.heading", defaultMessage: "Subjects edit"})}
            titleTypographyProps={{color: "primary"}}
          />
          <CardContent>
            <GraphQLEditForm id={id}
                             query={"subjects"}
                             mutations={"subject"}
                             fields={fields}
                             cols={2}
                             {...props}
            />
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <SubjectFieldForm subjectId={id} history={props.history}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <SubjectContactList
          {...props}
          subjectId={id}
        />
      </TabPanel>
    </>
  );
}
