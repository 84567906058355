import React from "react";
import ReactDOM from "react-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import {LocaleContextProvider} from "contexts/Intl";
import {AuthProvider} from "contexts/Auth";
import {createBrowserHistory} from "history";
import App from "contexts/App";

// Disable missing translation message as translations will be added later.
// We can add a toggle for this later when we have most translations.

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    String(message).indexOf('MISSING_TRANSLATION') > 0
  ) {
    return;
  }
  consoleError(message, ...args);
};

const hist = createBrowserHistory();

ReactDOM.render(
    <AuthProvider history={hist}>
        <LocaleContextProvider>
            <App history={hist}/>
        </LocaleContextProvider>
    </AuthProvider>,
    document.querySelector("#root")
);
