import {Home as HomeIcon} from "@material-ui/icons";
import Dashboard from "views/Admin/Dashboard";

export default function routes(intl) {
  const prefix = '/dialer';
  return [
    {
      path: "",
      exact: true,
      name: intl.formatMessage({id:"dialer.view", defaultMessage:"Dialer"}),
      icon: HomeIcon,
      component: Dashboard,
    },
  ].map(r => ({...r, path: prefix + r.path}));
}