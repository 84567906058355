import React, {useRef, useState} from "react";
import {Dialog, DialogContent, Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {Add, Delete, Flare} from "@material-ui/icons";
import {useIntl} from "react-intl";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";

export default function SubjectFieldsList(props) {
    const intl = useIntl();
    let assignmentId = props.match.params && props.match.params.assignmentID;

    const [showSelectFields, setShowSelectFields] = useState(false);

    const columns = [
        {title: intl.formatMessage({id:'subject_fields.list.column.name', defaultMessage:'Name'}), field: 'name'},
        {title: intl.formatMessage({id:'subject_fields.list.column.category', defaultMessage:'Category'}), field: 'category'},
        {title: intl.formatMessage({id:'subject_fields.list.column.import_key', defaultMessage:'Import key'}), field: 'import_key'},
        {title: intl.formatMessage({id:'subject_fields.list.column.shared', defaultMessage:'Shared'}), field: 'shared', type: 'boolean', initial: false}
    ];

    const tableRef = useRef();
    const client = new graphQLApi(useAuthDispatch(), props.history);
    const handleAddingFields = (rows) => {
      if (rows.length) {
        client.mutate('{assignmentSubjectFieldAdd(assignment_id:' + assignmentId + ', subject_field_ids:[' + rows.map(r => r.id).join(',') + ']){id}}').then(() => {
          if (tableRef.current) {
            tableRef.current.update();
          }
          setShowSelectFields(false);
        });
      }
    }
    const handleRemoveFields = (rows) => {
      if (rows.length) {
        client.mutate('{assignmentSubjectFieldRemove(assignment_id:' + assignmentId + ', subject_field_ids:[' + rows.map(r => r.id).join(',') + ']){id}}').then(() => {
          if (tableRef.current) {
            tableRef.current.clearSelected();
            tableRef.current.update();
          }
        });
      }
    }

    return (
      <Paper>
        <EnhancedTable
          {...props}
          ref={tableRef}
          title={intl.formatMessage({id:'subject_fields.list.table.heading', defaultMessage:"Assignments subject fields"})}
          columns={columns}
          query="assignmentSubjectFields"
          fields='id name category import_key shared'
          filter={'assignment_id:'+assignmentId}
          direction="asc"
          icon={<Flare/>}
          actions={[
            {
              tooltip: intl.formatMessage({id:"common.button.add"}),
              icon: Add,
              isFreeAction: true,
              onClick: () => setShowSelectFields(true),
            },
            {
              tooltip: intl.formatMessage({id:"common.button.remove"}),
              icon: Delete,
              onSelected: true,
              onClick: handleRemoveFields,
            }
          ]}
        />
          <Dialog open={showSelectFields} onClose={() => setShowSelectFields(false)}>
              <DialogContent>
                {showSelectFields && <EnhancedTable
                  {...props}
                  title={intl.formatMessage({
                    id: 'subject_fields.list.table.heading',
                    defaultMessage: "Subject fields"
                  })}
                  columns={columns.slice(0, 2)}
                  query="assignmentSubjectFields"
                  fields='id name category'
                  filter={'assignment_id_ne:' + assignmentId}
                  direction="asc"
                  icon={<Flare/>}
                  actions={[
                    {
                      tooltip: intl.formatMessage({id:"common.button.add"}),
                      icon: Add,
                      onSelected: true,
                      onClick: handleAddingFields,
                    }
                  ]}
                />}
              </DialogContent>
          </Dialog>
      </Paper>
    );
}