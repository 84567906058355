import React, { useEffect } from "react";
import moment from "moment";
import "moment/locale/da.js";
import "moment/locale/en-gb.js";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

export const LocaleContext = React.createContext();

export const LocaleContextProvider = (props) => {
  window.language = navigator.language.split(/[-_]/)[0];
  if (window.language !== "da") window.language = "en";
  const [locale, setLocale] = React.useState(window.language);
  useEffect(() => {
    window.language = locale;
    moment.locale(locale);
  }, [locale]);
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <LocaleContext.Provider value={[locale, setLocale]}>
        {props.children}
      </LocaleContext.Provider>
    </MuiPickersUtilsProvider>
  );
};
