import { Button, Link, Menu, MenuItem } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'

import componentStyles from 'assets/theme/components/admin-navbar.js'
import Breadcrumb from 'components/Breadcrumb'
// @material-ui/icons components
// core components
import NavbarDropdown from 'components/Dropdowns/NavbarDropdown.js'
import { config, getLayouts } from 'config'
import { authUser, useAuthDispatch } from 'contexts/Auth'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { graphQLApi } from 'services/GraphQLApi'

const useStyles = makeStyles(componentStyles);

export default function AdminNavbar({routes, logo, history}) {
  const classes = useStyles();
  const intl = useIntl();

  const getLayoutLinks = () => {
    let links = [];
    const layouts = getLayouts(intl);
    for (let key in layouts) {
      if (layouts[key].authenticated && authUser().isAllowed(layouts[key].acl) && !layouts[key].hidden) {
        links.push(layouts[key]);
      }
    }
    return links;
  }

  let logoImage = (
    <img alt={logo.imgAlt} className={classes.logoClasses} src={logo.imgSrc}/>
  );

  let logoObject =
    logo && logo.innerLink ? (
      <Link to={logo.innerLink} className={classes.logoLinkClasses}>
        {logoImage}
      </Link>
    ) : logo && logo.outterLink ? (
      <a href={logo.outterLink} className={classes.logoLinkClasses}>
        {logoImage}
      </a>
    ) : null;

    const client = new graphQLApi(useAuthDispatch());
    const [anchorEl, setAnchorEl] = React.useState();
    const [links, setLinks] = React.useState([]);

    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      const layoutLinks = async () => {
        const links = getLayoutLinks().map(async (layout, key) => {
          if (layout.contextQuery && layout.contextResultTitles) {
            const r = await client.query('{'+layout.contextQuery+'}');
            const titles = layout.contextResultTitles(r);
            if (!titles || titles.length === 0) return null;
            return (
              <div key={"layouts-" + key}>
                <Button startIcon={<layout.icon/>} onClick={handleMenuOpen}>{layout.title}</Button>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  style={{marginTop:25}}
                >
                  {titles.map((title, key) => (
                    <Box
                      key={"admin-navbar-titles-"+key}
                      display="flex!important"
                      alignItems="center!important"
                      component={MenuItem}
                      onClick={() => {handleMenuClose();history.push(layout.defaultRoute+'/'+title.id);}}
                    >
                      <span>{title.title}</span>
                    </Box>
                  ))}
                </Menu>
              </div>
            );
          }
          else return (<Button startIcon={<layout.icon/>} key={"layouts-" + key} onClick={() => history.push(layout.defaultRoute ? layout.defaultRoute : layout.path)}>{layout.title}</Button>);
        });
        const allLinks = await Promise.all(links);
        setLinks(allLinks);
      };
      layoutLinks();
    }, [anchorEl]);

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        classes={{root: classes.appBarRoot}}
      >
        <Toolbar disableGutters classes={{root: classes.toolbarRoot}}>
          <Container
            maxWidth={false}
            component={Box}
            classes={{root: classes.containerRoot}}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box display="flex" alignItems="center" width="auto">
                <Box paddingBottom="1rem" className={classes.logoBox}>{logoObject}</Box>
              {config.layoutNavigation === "horisontal"
                ? links
                : <Breadcrumb routes={routes} history={history}/>}
              </Box>
              <Box display="flex" alignItems="center" width="auto">
                <NavbarDropdown history={history}/>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
