import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {Assignment} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function AssignmentEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);
    let assignmentId = props.match.params.assignmentID;
    if (!id && assignmentId) {
        id = assignmentId;
    }

    const fields = [
        {
            column: 1,
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            column: 1,
            field: "customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.customer_id", defaultMessage: "Customer"}),
            query: "customers",
            filter: "",
            titleField: "name"
        },
        {
            column: 1,
            field: "contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.contact_id", defaultMessage: "Contact"}),
            query: "customerContacts",
            filter: "",
            titleField: "name"
        },
        {
            column: 1,
            field: "manager_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.manager_id", defaultMessage: "Manager"}),
            query: "users",
            filter: 'role:"admin,manager"',
            titleField: "name"
        },
        {
            column: 1,
            field: "provider_customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.provider_customer_id", defaultMessage: "Provider Customer"}),
            query: "customers",
            filter: "",
            titleField: "name"
        },
        {
            column: 1,
            field: "max_closed_subjects",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "assignments.edit.label.max_closed_subjects", defaultMessage: "Maximum number of closed subjects"}),
            input: "number"
        },
        {
            column: 1,
            field: "auto_assign_subjects",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "assignments.edit.label.auto_assign_subjects", defaultMessage: "Auto assing subjects when phoner has no more ready subjects"}),
            input: "number"
        },
        {
            column: 1,
            field: "low_subjects_alert_level",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "assignments.edit.label.low_subjects_alert_level", defaultMessage: "Below this amount of ready subjects sends alert email"}),
            input: "number"
        },
        {
            column: 1,
            field: "low_subjects_alert_recipients",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.low_subjects_alert_recipients", defaultMessage: "Alert email recipients (empty sends to manager)"}),
            input: "text"
        },
        {
            column: 1,
            field: "is_screening",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_screening", defaultMessage: "Is Screening"}),
            input: "switch"
        },
        {
            column: 1,
            field: "is_template",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_template", defaultMessage: "Is Template"}),
            input: "switch"
        },
        {
            column: 1,
            field: "is_active",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_active", defaultMessage: "Is Active"}),
            input: "switch"
        },
        {
            column: 2,
            field: "move_all_callbacks",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.move_all_callbacks", defaultMessage: "Move all callbacks after contact expiration"}),
            input: "switch"
        },
        {
            column: 2,
            field: "never_move_callbacks",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.never_move_callbacks", defaultMessage: "Never move callbacks automatically"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_meeting_invites",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_meeting_invites", defaultMessage: "Allow phoner to send meeting invites to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_direct_mail",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_direct_mail", defaultMessage: "Allow phoner to send direct mails to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_alt_mail_1",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_alt_mail_1", defaultMessage: "Allow phoner to send alternativ 1 mails to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_alt_mail_2",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_alt_mail_2", defaultMessage: "Allow phoner to send alternativ 2 mails to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_sms",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_sms", defaultMessage: "Allow phoner to send SMS messages to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "add_phoner_comment_details",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.add_phoner_comment_details", defaultMessage: "Add phoner name and date on comments"}),
            input: "switch"
        },
        {
            column: 2,
            field: "reset_closed_subjects_daily",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.reset_closed_subjects_daily", defaultMessage: "Reset closed subjects daily"}),
            input: "switch"
        },
        {
            column: 2,
            field: "is_manager_bcc",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_manager_bcc", defaultMessage: "BCC manager on all mails"}),
            input: "switch"
        },
        {
            column: 3,
            field: "sms_template",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.sms_template", defaultMessage: "SMS Template"}),
            input: "text",
            lines: 10,
        },
        {
            column: 3,
            field: "notes",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.notes", defaultMessage: "Notes"}),
            input: "text",
            lines: 10,
        },
        {
            column: 3,
            field: "contract",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.contract", defaultMessage: "Contract"}),
            input: "file",
            accepts: "*",
        },
        {
            column: 3,
            field: "last_report_at",
            initial: null,
            disabled: () => true,
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.last_report_at", defaultMessage: "Last Report At"}),
            input: "datetime"
        },
        {
            column: 3,
            field: "last_billed_at",
            initial: null,
            disabled: () => true,
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.last_billed_at", defaultMessage: "Last Billed At"}),
            input: "datetime"
        },
        {
            fullWidth: true,
            field: "phoners",
            initial: [],
            multiple: true,
            type: "[ID]",
            label: intl.formatMessage({id: "assignments.edit.label.phoners", defaultMessage: "Phoners"}),
            query: "users",
            filter: 'role:"employee"',
            titleField: "name"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Assignment color="primary"/>}
                        title={intl.formatMessage({id: "assignments.edit.heading", defaultMessage:"Assignments edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"assignments"}
                                         mutations={"assignment"}
                                         fields={fields}
                                         cols={3}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
