import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/core/styles";
import theme from "assets/theme/theme";
import ReleaseVersionChecker from 'components/ReleaseVersionChecker';
import {LocaleContext} from "contexts/Intl";
import React from "react";
import {IntlProvider} from "react-intl";
import locale_da from "translations/da.json";
import locale_en from "translations/en.json";
import Layouts from "./Layouts";

export default function App() {
  let [locale] = React.useContext(LocaleContext);
  const translations = {
    en: locale_en,
    da: locale_da,
  };

  return <IntlProvider
    locale={locale}
    defaultLocale="en"
    messages={translations[locale]}
  >
    <ThemeProvider theme={theme}>
      <ReleaseVersionChecker />
      <CssBaseline/>
      <Layouts/>
    </ThemeProvider>
  </IntlProvider>;
}