import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Group} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function CustomerContactEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);
    let customerId = Number(props.match.params.customerId);

    const fields = [
        {
            field: "customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "customer_contacts.edit.label.customer_id", defaultMessage: "Customer"}),
            query: "customers",
            filter: "",
            titleField: "name"
        },
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "phone",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.phone", defaultMessage: "Phone"}),
            input: "text"
        },
        {
            field: "mobile",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.mobile", defaultMessage: "Mobile"}),
            input: "text"
        },
        {
            field: "email",
            initial: "",
            type: "Email",
            label: intl.formatMessage({id: "customer_contacts.edit.label.email", defaultMessage: "Email"}),
            input: "email"
        },
        {
            field: "calendar_url",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "customer_contacts.edit.label.calendar_url", defaultMessage: "Calendar Url"}),
            input: "text"
        }
    ];

    if (customerId) {
        fields.shift();
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Group color="primary"/>}
                        title={id
                            ? intl.formatMessage({id: "customer_contacts.edit.heading", defaultMessage:"Edit consultant"})
                            : intl.formatMessage({id: "customer_contacts.create.heading", defaultMessage:"Create consultant"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"customerContacts"}
                                         mutations={"customerContact"}
                                         fields={fields}
                                         cols={2}
                                         extraArgs={customerId ? 'customer_id:' + customerId : ''}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
